import React from "react";
import {
  ImageContent,
  ServicesList,
  Brands,
  StayUp,
  ClientMeeting,
} from "../../components";
import { ServicesData } from "../../data/servicesData";

const ServicesPage = (props) => {
  const [imageContentData, ourServices, brandsData] = ServicesData;

  return (
    <div className="services">
      <ImageContent imageContentData={imageContentData.imageContentData[0]} />
      <ServicesList servicesList={ourServices.ourServices} />
      <ImageContent imageContentData={imageContentData.imageContentData[1]} />
      <Brands brandsData={brandsData.brandsData} />
      <ClientMeeting />
    </div>
  );
};

export default ServicesPage;
