import { combineReducers } from 'redux';
import test from './test-reducer';
import news from './news-reducer';
import newsdetails from './news-details-reducer';
import email from './email-reducer';

const rootReducer = combineReducers({
    newsData: news,
    newsDetailsData: newsdetails,
    emailData: email,
});

export default rootReducer;