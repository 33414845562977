import {
  FlexBanner,
  FilterImage,
  PlaneIcon,
  ClockIcon,
  BookIcon,
  StrokeIcon,
  StrokeIconBlue,
  PaymentIcon,
  PlaneIconBlue,
  ClockIconBlue,
  BookIconBlue,
  PaymentIconBlue,
  OptimeLeftImage,
  OptimeRightImage,
  OptimeLeftMobile,
  OptimeRightMobile,
  FlexLogo,
  FlexBannerNew,
  Step1,
  Step2,
  Step3,
  Step4,
} from "../components/UIComponent/images";

export const flexData = [
  {
    imageContentData: [
      {
        pageName: "FLEX",
        bannerLogo: `${FlexLogo}`,
        title: "<strong>Connecting</strong> you with a runway-ready workforce",
        description:
          "Optime Flex unlocks skilled, fully compliant, contingent workers for your operation – Book through our app today!",
        src: `${FlexBannerNew}`,
        ctaText: "Learn More",
        ctaLink: "#",
        background: true,
        figureFirst: false,
        banner: true,
        scrollSection: "how-it-works",
      },
    ],
  },
  {
    filterData: {
      title: "How it works",
      steps: [
        {
          stepIcon: ``,
          stepActiveIcon: `${PlaneIcon}`,
          stepNum: "Step 1",
          stepImage: `${StrokeIconBlue}`,
          stepActiveImage: `${StrokeIcon}`,
          stepName: "Publish shifts",
        },
        {
          stepIcon: ``,
          stepActiveIcon: `${PlaneIcon}`,
          stepNum: "Step 2",
          stepImage: `${BookIconBlue}`,
          stepActiveImage: `${BookIcon}`,
          stepName: "Book Staff",
        },
        {
          stepIcon: ``,
          stepActiveIcon: `${PlaneIcon}`,
          stepNum: "Step 3",
          stepImage: `${ClockIconBlue}`,
          stepActiveImage: `${ClockIcon}`,
          stepName: "Clock-in / out",
        },
        {
          stepIcon: ``,
          stepActiveIcon: `${PlaneIcon}`,
          stepNum: "Step 4",
          stepImage: `${PaymentIconBlue}`,
          stepActiveImage: `${PaymentIcon}`,
          stepName: "Payment",
        },
      ],
      stepsData: [
        {
          stepNum: "Step 1",
          stepTitle:
            "Publish shifts online through a <strong>dedicated online platform</strong>",
          description:
            "Whether you’re uplifting your headcount for a peak period, reacting to an event or simply looking for additional cover, login to your bespoke platform and publish shifts up to 4 weeks in advance.",
          ctaText: "Next Step",
          ctaLink: "#",
          filterImage: `${Step1}`,
        },
        {
          stepNum: "Step 2",
          stepTitle:
            "Confirm staff <strong>quickly and easily</strong> by logging into your online portal",
          description:
            "All staff enrolled in Optime Flex will be able to view your published shifts, providing they meet the pre-defined criteria such as airside pass status and skillset. View which staff have booked themselves onto the shift and simply confirm at the click of a button!",
          ctaText: "Next Step",
          ctaLink: "#",
          filterImage: `${Step2}`,
        },
        {
          stepNum: "Step 3",
          stepTitle:
            "Paperless timesheets are generated through our dedicated app, <strong>saving you time</strong> and admin burden",
          description:
            "Our flex workers can clock in and out using the Optime Flex app. We use multi-factor authentication plus GPS to confirm that colleagues have arrived on time at the correct location before a timesheet can be populated.",
          ctaText: "Next Step",
          ctaLink: "#",
          filterImage: `${Step3}`,
        },
        {
          stepNum: "Step 4",
          stepTitle:
            "Optime Flex workers can unlock <strong>immediate payment.</strong>",
          description:
            "Our secure web portal allows shift managers to securely sign off timesheets captured on the app, releasing immediate payment to Optime Flex workers.",
          ctaText: "Next Step",
          ctaLink: "#",
          filterImage: `${Step4}`,
        },
      ],
    },
  },
  {
    flexSignup: {
      title:
        "Realise the potential of your operation with Optime Flex.",
      contentTitle: "Tomorrow’s workforce, <strong>today.</strong>",
      contentDescription:
        "We’re currently rolling out our Flex product in Gatwick and Heathrow. For early access, get in touch and learn how our modern solution can transform your airport operation.",
      ctaLink: "/contact",
      ctaText: "Get in touch",
      contactState: "optime-flex",
      leftImage: `${OptimeLeftImage}`,
      rightImage: `${OptimeRightImage}`,
      leftMobileImage: `${OptimeLeftMobile}`,
      rightMobileImage: `${OptimeRightMobile}`,
    },
  },
];
