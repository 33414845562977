import React from "react";

const AboutValue = (props) => {
  const { title, description, valuesList } = props.valueData;

  return (
    <div className="about-value">
      <div className="wrapper">
        <h3>{title}</h3>
        <p>{description}</p>
        {Array.isArray(valuesList) && valuesList.length ? (
          <ul className="d-flex justify-content-between">
            {valuesList.map((items, index) => {
              const { title, imgSrc } = items;

              return (
                <li key="index">
                  <h4>{title}</h4>

                  <figure>
                    <img src={imgSrc} alt={title}/>
                  </figure>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default AboutValue;
