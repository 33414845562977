import React from "react";
import { CTA } from "..";
import { BannerFirstPerson, BannerSecondPerson } from "./images";

const FlexSignUp = (props) => {
  const {
    title,
    contentTitle,
    contentDescription,
    ctaLink,
    ctaText,
    leftImage,
    rightImage,
    leftMobileImage,
    rightMobileImage,
    contactState
  } = props.flexSignupData;

  return (
    <div className="flex-signup">
      <figure className="left-image">
        <img src={leftImage} className="desktop-image" />
        <img src={leftMobileImage} className="mobile-image" />
      </figure>
      <h2 dangerouslySetInnerHTML={{ __html: title }} />
      <div className="mobile-image-list">
        <figure>
          <img src={BannerFirstPerson} />
        </figure>
        <figure>
          <img src={BannerSecondPerson} />
        </figure>
      </div>
      <div className="content">
        <h3 dangerouslySetInnerHTML={{ __html: contentTitle }} />
        <p>{contentDescription}</p>
        <CTA ctaText={ctaText} ctaLink={ctaLink} contactState={contactState} />
      </div>
      <figure className="right-image">
        <img src={rightImage} className="desktop-image" />
        <img src={rightMobileImage} className="mobile-image" />
      </figure>
    </div>
  );
};

export default FlexSignUp;
