import React from "react";
import { ServiceCard } from "..";

const ServicesList = (props) => {

    const { title, servicesList} = props.servicesList; 

    return (
        <div className="services-list" id="service-list">
            <div className="wrapper">
                <h3>{title}</h3>
                { Array.isArray(servicesList) && servicesList.length ? (
                    <ul className="d-flex justify-content-between">
                        {servicesList.map((items, index) => {
                            return(
                                <ServiceCard data={items} />
                            )
                        })}
                    </ul>
                ) : null }
            </div>
        </div>
    )
}

export default ServicesList;