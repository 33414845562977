import React from "react";
import { Link } from "react-router-dom";
import { JobCard, CTA } from "..";

const FeaturedJob = (props) => {

    const data = props.featuredJobData; 

    const { title, description, cta, cards } = data;


    return (
        <div className="featured-job" id="feature-job">
            <div className="content wrapper">
                <h3>{title}</h3>
                <p>{description}</p>
                <a className="cta" href="https://apply.workable.com/optimegroup/" target="_blank">{cta}</a>

                <ul className="d-flex justify-content-center">
                    {cards.map((items, index) => {
                        return(
                            <JobCard cards={items} key={index} />
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default FeaturedJob