import React from "react";

const NewsBanner = (props) => {

    const { title, description } = props.newsData

    return(
        <div className="news-banner banner">
            <div className="wrapper">
                <h2 dangerouslySetInnerHTML={{__html: title}}/>
                <p>{description}</p>
            </div>
        </div>
    )
}
export default NewsBanner;