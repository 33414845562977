// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// dev configuration 
const firebaseConfig = {
  apiKey: "AIzaSyBK9jlLsDfdAQqPh2Hq9K3BiqcI9IRoVNI",
  authDomain: "optimewebpa.firebaseapp.com",
  projectId: "optimewebpa",
  databaseURL: "https://optimewebpa-default-rtdb.firebaseio.com/",
  storageBucket: "optimewebpa.appspot.com",
  messagingSenderId: "862402072572",
  appId: "1:862402072572:web:99ebebe131721427f22c79",
};

// production configuration  
// const firebaseConfig = {
//   apiKey: "AIzaSyCb-vidgaFGxgYQ2kq51jVVWe-gIPgyCs4",
//   authDomain: "optime-website-cms.firebaseapp.com",
//   databaseURL: "https://optime-website-cms-default-rtdb.firebaseio.com",
//   projectId: "optime-website-cms",
//   storageBucket: "optime-website-cms.appspot.com",
//   messagingSenderId: "374454337856",
//   appId: "1:374454337856:web:a9daeeffd351d7f6957aa5",
//   measurementId: "G-YJB1VS30ZZ",
// }

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const database = getDatabase();

export { app, auth, database };
