import React from "react";
import CTA from "./cta";

const RecruitmentCard = (props) => {
  console.log(props);
  const { imageSrc, titleImage, listTitle, description, ctaText, ctaLink, contactState } =
    props.content;

  return (
    <li>
      <figure>
        <img src={imageSrc} />
      </figure>
      <div className="recruitment-content">
        <div className="title-content d-flex justify-content-center align-items-center">
          <img src={titleImage} />
          <h4>{listTitle}</h4>
        </div>
        <p>{description}</p>
        <CTA ctaText={ctaText} ctaLink={ctaLink} contactState={contactState} />
      </div>
    </li>
  );
};

export default RecruitmentCard;
