import React from "react";
import NewsletterForm from "./Newsletterform";

import { Newsletter, BellIcon } from "./images";

const StayUp = () => {
  return (
    <div className="stayup-container">
      <div className="stayup-content">
        <div className="content">
          <div className="title-container d-flex justify-content-start align-items-center">
            <img src={BellIcon} alt="Bell Icon" />
            <h2>Stay up to date with the latest jobs!</h2>
          </div>
          <p>
            Keep the latest airport job opportunities on your radar by subscribing to our weekly mailing list.
          </p>
          <NewsletterForm />
        </div>
        <figure>
          <img src={Newsletter} alt="Newsletter" />
        </figure>
      </div>
    </div>
  );
};

export default StayUp;
