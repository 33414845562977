import React from "react";
import { CTA } from "..";

const ImageContent = (props) => {
  const data = props.imageContentData;
  const {
    pageName,
    bannerLogo,
    title,
    description,
    discoverTitle,
    links,
    src,
    background,
    figureFirst,
    banner,
    ctaText,
    ctaLink,
    contactState,
    scrollSection,
    scrollId
  } = data;

  return (
    <div
    id={scrollId ? scrollId : null}
      className={
        background === true && figureFirst === false && banner === true
          ? "expertise-section background-active banner"
          : background === true && figureFirst === false && banner === false
          ? "expertise-section background-active"
          : background === false && figureFirst === false && banner === false
          ? "expertise-section background-in-active image-second"
          : "expertise-section background-in-active"
      }
    >
      <div className="container d-flex justify-content-between">
        <div className="content">
          <div>
            {/* {pageName !== "" ? (
              <span className="banner-text">{pageName}</span>
            ) : null} */}
            {bannerLogo ? (
              <img className="banner-logo" src={bannerLogo} alt="Optime Flex" />
            ) : null}
          </div>
          <h1 className="title" dangerouslySetInnerHTML={{ __html: title }} />
          <p className="description"  dangerouslySetInnerHTML={{__html: description}}/>
          <div className="links-container">
            <h5 className="discover-title">{discoverTitle}</h5>
            {Array.isArray(links) && links.length ? (
              <ul className="d-flex">
                {links.map((items, index) => {
                  return (
                    <li key={index}>
                      <CTA ctaText={items.linkTitle} ctaLink={items.linkUrl} />
                    </li>
                  );
                })}
              </ul>
            ) : null}
            {ctaLink && ctaText ? (
              <CTA ctaText={ctaText} ctaLink={ctaLink} contactState={contactState} scrollSection={scrollSection} />
            ) : null}
          </div>
        </div>
        <figure>
          <img src={src} alt={title} />
        </figure>
      </div>
    </div>
  );
};

export default ImageContent;
