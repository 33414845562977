import React from "react";
import { BackgroundCheckData } from "../../data/backgroundCheckData";
import {
  Brands,
  Candidate,
  ClientMeeting,
  ImageContent,
  OnlinePortalContainer,
  StayUp,
  TrainingApproach,
} from "../../components";
import { FooterLine, FooterMobile } from "../../components/UIComponent/images";

const BackgroundCheck = () => {
  const [imageContentData, ourApproach ,onlinePortal, candidates, brandsData] =
    BackgroundCheckData;

  return (
    <div className="background-check">
      <ImageContent imageContentData={imageContentData.imageContentData[0]} />
      <div className="dotted-line-container wrapper">
        <figure>
          <img src={FooterLine} className="desktop-line" />
          <img src={FooterMobile} className="mobile-line" />
        </figure>
      </div>
      <TrainingApproach ourApproach={ourApproach.ourApproach} />
      {/* <ImageContent imageContentData={imageContentData.imageContentData[1]} /> */}
      <OnlinePortalContainer onlinePortalData={onlinePortal.onlinePortal} />
      <ImageContent imageContentData={imageContentData.imageContentData[2]} />
      <Candidate candidateData={candidates.candidatesData} />
      {/* <Brands brandsData={brandsData.brandsData} /> */}
      <ClientMeeting />
      {/* <StayUp /> */}
    </div>
  );
};

export default BackgroundCheck;
