import React from "react";
import { Link } from "react-router-dom";
import NewsDetail from "../../screens/News/News-Detail";
import moment from "moment";

const NewsCard = (props) => {
  const {
    imageUrl,
    publishDate,
    title,
  } = props.newsData;

  const newsDate = moment(publishDate, "MM/DD/YYYY").format("MMMM-D-YYYY");

  return (
    <div className="news-item">
      <figure>
        <img src={imageUrl} alt="News Image" />
      </figure>
      <div className="content">
        <span>{newsDate}</span>
        <p>{title}</p>
        <Link
          to={{
            pathname: `/news/${encodeURIComponent(props.newsData.title)}/${props.newsId}`,
            state: { data: props.newsData },
          }}
        >Read Article
        </Link>
        {/* <Switch>
          <Route
            path={`/blog/:id`}
            exact
            component={(props) => (
              <NewsDetail newsDetailData={props.newsData} />
            )}
          />
        </Switch> */}
      </div>
    </div>
  );
};

export default NewsCard;
