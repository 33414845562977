import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";


const FilterComponent = (props) => {
  const { title, steps, stepsData } = props.filterData;
  const [mobileDevice, setMobileDevice] = useState(false);
  const [filterState, setFilterState] = useState(0);

  useEffect(() => {
    console.log(filterState);
    if (isMobile) {
      setMobileDevice(true);
      console.log(mobileDevice);
    } else {
      setMobileDevice(false);
      console.log(mobileDevice);
    }
  }, [filterState]);

  const handleClick = (e) => {
    e.stopPropagation();
    let val = e.target.getAttribute("attr");
    if (parseInt(val) === 0) {
      setFilterState(0);
    } else {
      setFilterState(parseInt(val) - 1);
    }
  };

  const handleNext = (e) => {
    e.stopPropagation();
    let val = e.target.getAttribute("attr");
    if (parseInt(val) === 3) {
      setFilterState(3);
    } else {
      setFilterState(parseInt(val) + 1);
    }
  };

  const { stepNum, stepTitle, description, ctaText, ctaLink, filterImage } =
    stepsData[filterState];

  return (
    <div className="filter-container " id="how-it-works">
      <div className="wrapper">
        <h3 className="title">{title}</h3>
        <div className="filter">
          {Array.isArray(steps) ? (
            <ul className="tabs d-flex justify-content-between">
              {steps.map((items, index) => {
                let {
                  stepIcon,
                  stepActiveIcon,
                  stepNum,
                  stepImage,
                  stepActiveImage,
                  stepName,
                } = items;
                return (
                  <li
                    onClick={() => setFilterState(index)}
                    className={index === filterState ? "active" : null}
                    key={index}
                  >
                    <div className="step-container">
                      <div className="step">
                        {stepActiveIcon && index === filterState ? (
                          <img src={stepActiveIcon} />
                        ) : stepIcon && stepActiveIcon ? (
                          <img src={stepIcon} />
                        ) : null}
                        <span>{stepNum}</span>
                      </div>
                      <div className="step-name">
                        {stepImage &&
                        stepActiveImage &&
                        index === filterState ? (
                          <img src={stepActiveImage} />
                        ) : stepImage && stepActiveImage ? (
                          <img src={stepImage} />
                        ) : null}
                        <span>{stepName}</span>
                      </div>
                    </div>
                    <div className="filter-content mobile-filter d-flex justify-content-between mobile-data">
                      <div className="content">
                        <span>{stepNum}</span>
                        <h2 dangerouslySetInnerHTML={{ __html: stepTitle }} />
                        <p>{description}</p>
                        <div className="d-flex justify-content-start">
                          <button
                            className={
                              filterState === 0 ? "cta disabled" : "cta"
                            }
                            disabled={filterState === 0 ? true : false}
                            attr={index}
                            onClick={(e) => handleClick(e)}
                          >
                            Previous step
                          </button>
                          <button
                            className={
                              filterState === 3
                                ? "cta ml-2 disabled"
                                : "cta ml-2"
                            }
                            disabled={filterState === 3 ? true : false}
                            attr={index}
                            onClick={(e) => handleNext(e)}
                          >
                            Next step
                          </button>
                          {/* <CTA ctaText={ctaText} ctaLink={ctaLink} /> */}
                        </div>
                        {/* <CTA ctaText={ctaText} ctaLink={ctaLink} /> */}
                      </div>
                      <figure>
                        <img src={filterImage} />
                      </figure>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : null}
          <div className="filter-content d-flex justify-content-between">
            <div className="content">
              <span>{stepNum}</span>
              <h2 dangerouslySetInnerHTML={{ __html: stepTitle }} />
              <p>{description}</p>
              <div>
                <button
                  className={filterState === 0 ? "cta disabled" : "cta"}
                  disabled={filterState === 0 ? true : false}
                  onClick={() =>
                    filterState === 0
                      ? setFilterState(0)
                      : setFilterState(filterState - 1)
                  }
                >
                  Previous step
                </button>
                <button
                  className={
                    filterState === 3 ? "cta ml-2 disabled" : "cta ml-2"
                  }
                  disabled={filterState === 3 ? true : false}
                  onClick={() =>
                    filterState === 3
                      ? setFilterState(3)
                      : setFilterState(filterState + 1)
                  }
                >
                  Next step
                </button>
                {/* <CTA ctaText={ctaText} ctaLink={ctaLink} /> */}
              </div>
            </div>
            <figure>
              <img src={filterImage} />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
