import React from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  Logo,
  FooterMember,
  ContactLogo,
  Facebook,
  Twitter,
  LinkedIn,
  FooterLine,
  FooterMobile,
  FooterTabLine,
  Instagram,
} from "./images";

import FormContainer from "./Form";

const PrivacyPolicy = "https://storage.googleapis.com/optime-website-cms.appspot.com/docs%2FPrivacy-Policy-December-2021.pdf";
const ModerSlavery = "https://storage.googleapis.com/optime-website-cms.appspot.com/docs%2FModern-Slavery-Statement.pdf";


const Footer = (props) => {

  let year = new Date().getFullYear();

  const copyRightText = `Optime is a trading name of Optime Group Limited <br> Company Registration Number: 10424768 <br> Copyright ${year} Optime Group Limited`

  return (
    <footer className="page-footer font-small  pt-4">
      <div className="footer-content">
        <div className="container-fluid  text-md-left">
          <div className="row d-flex justify-content-between">
            <div className="footer-logo-container">
              <Link to="/" title="Optime Logo" className="footer-logo">
                <img src={Logo} alt="Optime Logo" />
              </Link>
              {/* <p className="description">
                We offer quality services with the best possible performance and
                have developed a strong reputation for efficiency and
                reliability.
              </p> */}

              <div className="list-container">
                <h4><Link to="/services">Services</Link></h4>
                <ul className="d-flex footer-links services-link">
                  <li>
                    <Link to="/recruitment">Recruitment Services</Link>
                  </li>
                  <li>
                    <Link to="/background-check">Background Checks</Link>
                  </li>
                  <li>
                    <Link to="/training">Training</Link>
                  </li>
                  {/* <li>
                    <Link to="/flex">Flex</Link>
                  </li> */}
                </ul>
              </div>

              <div className="list-container">
                <h4>Other Links</h4>
                <ul className="d-flex footer-links other-links">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/news">Latest News</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <a href={PrivacyPolicy}>Privacy Policy</a>
                  </li>
                  <li>
                    <a href={ModerSlavery}>Modern Slavery</a>
                  </li>
                </ul>
              </div>
              <div className="contact-details">
                <img src={ContactLogo} />
                <a
                  className="contact-number"
                  href="tel:01293 220 928"
                  title="Contact Us"
                >
                  01293 220 928
                </a>
              </div>
              <ul className="social-share d-flex jusfity-content-start">
                <li>
                  <a href="https://twitter.com/optime_group" target="_blank">
                    <img src={Twitter} />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/optimegroup/" target="_blank">
                    <img src={Facebook} />
                  </a>
                </li>
                <li>
                  <a href="https://uk.linkedin.com/company/optime-group-limited" target="_blank">
                    <img src={LinkedIn} />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/optimegroup/" target="_blank">
                    <img src={Instagram} />
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer-form-container">
              <div className="title-container d-flex">
                <h2>Get in touch!</h2>
                <p className="respond">We respond within 48 hours</p>
              </div>
              <p className="form-description">
                We answer all email and requests as they come in. If you have an
                urgent matter or would like to place an order please click the
                link below to give us a call.
              </p>

              <FormContainer select={false} />
            </div>
          </div>
        </div>

        <div className="footer-copyright d-flex justify-content-between py-3">
          <figure className="line">
            <img src={FooterLine} className="desktop-line" />
            <img src={FooterMobile} className="mobile-line" />
            <img src={FooterTabLine} className="tab-line" />
          </figure>
          <p dangerouslySetInnerHTML={{ __html: copyRightText }} />
          <figure>
            <img src={FooterMember} alt="Member Logo" />
          </figure>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
