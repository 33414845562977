import React from "react";
import { Link } from "react-router-dom";
import CTA from "./cta";

import { Line, DividingLine, Pin, ServiceMobileDivider } from "./images";

const JobCard = (props) => {
  const { src, title, location, shortname, ctatext } = props.cards;

  return (
    <li>
      <figure>
        <img src={src} alt={title} />
      </figure>
      <h4>{title}</h4>
      <div className="location-wrap d-flex justify-content-between align-items-center">
        <img src={Pin} alt="Location Pin Icon" />
        <span className="location">{location}</span>
        <span>{shortname}</span>
      </div>
      <figure className="dotted">
        <img src={ServiceMobileDivider} />
      </figure>
      <div className="cta-container">
        <a className="cta"  href="https://apply.workable.com/optimegroup/" target="_blank">{ctatext}</a>
      </div>
    </li>
  );
};

export default JobCard;
