import React from "react";
import ContactCards from "./ContactCard";
import {
  BannerBackground,
  BannerBackgroundMobile,
  ContactBackground,
} from "./images";

const ContactList = (props) => {
  const { title, description, descriptionTwo, locationList } =
    props.contactList;
  return (
    <div className="contact-list">
      <div className="wrapper">
        <h3>{title}</h3>
        <div className="description">
          <p>{description}</p>
          <p>{descriptionTwo}</p>
        </div>

        {Array.isArray(locationList) && locationList.length ? (
          <ul className="d-flex justify-content-between flex-wrap">
            {locationList.map((items, index) => {
              return <ContactCards cardData={items} />;
            })}
          </ul>
        ) : null}
      </div>
      <figure>
        {/* <img
          className="banner-desktop"
          src={BannerBackground}
          alt="Banner City"
        /> */}
        <img
          className="banner-mobile"
          src={ContactBackground}
          alt="Banner City"
        />
      </figure>
    </div>
  );
};

export default ContactList;
