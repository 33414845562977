const Logo = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Logo.svg";
const ServiceArrow = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Services-arrow.svg";
const FooterMember = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Footer-member.svg";
const Newsletter = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/newsletter.svg";
const BellIcon = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Bell-icon.svg";
const BannerBackground = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Illustration.svg";
const BannerBackgroundMobile = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Illustration-mobile.svg";
const BannerFirstPerson = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/banner-image-1.svg";
const BannerSecondPerson = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/banner-image-2.svg";
const DoubleDownArrow = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Double-down-arrow.svg";
const Gatwick = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Gatwick.svg";
const Easyjet = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/EasyJet_logo.svg";
const BritishAirway = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/British_Airways_Logo.svg";
const AircraftCleaner = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Aircraft-cleaner.svg";
const BaggageHandler = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Baggage-handler.svg";
const CheckInAgent = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Check-in-agent.svg";
const Line = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Line.svg";
const Pin = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Pin.svg";
const Expertise = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Expertise.svg";
const Mission = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Mission.svg";
const NewsImage = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/News.svg";
const ServicesBanner = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Services-banner.svg";
const PuzzleIcon = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Puzzle-icon.svg";
const ServiceDivider = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Service-divider.svg";
const AirCanada = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/AirCanada.svg";
const Bristol = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Bristol.svg";
const Belfast = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Belfast.svg";
const DHL = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/DHL.svg";
const Dnata = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Dnata.svg";
const Dufry = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Dufry.svg";
const Heathrow = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Heathrow.svg";
const London = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/London.svg";
const LondonAirport = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/LondonAirport.svg";
const Menzies = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Menzies.svg";
const OCS = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Ocs.svg";
const Restaurant = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Restaurant.svg";
const Virginatlantic = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Virginatlantic.svg";
const Wilson = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Wilson.svg";
const FlexBanner = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/flexBanner.svg";
const FilterImage = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/filterImage.svg";
const PlaneIcon = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/plane-icon.svg";
const PlaneIconBlue = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/plane-icon-blue.svg";
const BookIcon = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/book-icon.svg";
const BookIconBlue = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/book-icon-blue.svg";
const StrokeIcon = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/stroke-icon.svg";
const ClockIcon = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/clock-icon.svg";
const ClockIconBlue = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/clock-icon-blue.svg";
const PaymentIcon = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/payment-icon.svg";
const PaymentIconBlue = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/payment-icon-blue.svg";
const StrokeIconBlue = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/stroke-icon-blue.svg";
const OptimeLeftImage = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Optime-Left.svg";
const OptimeRightImage = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Optime-Right.svg";
const OptimeRightMobile = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/flexRightMobile.svg";
const OptimeLeftMobile = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/flexLeftMobile.svg";
const TicketDivider = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Ticket-Divider.svg";
const DividingLine = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Dividing-Line.svg";
const FlexLogo = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Flex-logo.svg";
const ContactLogo = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/contact.svg";
const RecruitmentTemporary = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/recruitment-temporary.svg";
const RecruitmentPermanent = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/recruitment-permanent.svg";
const RecruitmentOnSite = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/recruitment-on-site.svg";
const RecruitmentBanner = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/recruitment-banner.svg";
const BackgroudCheckBanner = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/BackgroundBanner.svg";
const OnlinePortal = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Online-portal.svg";
const Candidate = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/candidate.svg";
const TrainingBanner = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Training-Banner.svg";
const TrainingTeam = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Training-team.svg";
const TrainingWork = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Training-work.svg";
const TrainingOne = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/training-one.svg";
const TrainingTwo = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/training-two.svg";
const TrainingThree = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/training-three.svg";
const TrainingFour = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/training-four.svg";
const TrainingFive = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/training-five.svg";
const TrainingSix = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/training-six.svg";
const TrainingSeven = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/training-seven.svg";
const TrainingEight = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/training-eight.svg";
const TrainingNine = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/training-nine.svg";
const TrainingTen = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/training-ten.svg";
const ContactLocation = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/contact-list.jpg";
const ContactBackground = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/contact-background.svg";
const OurJourney = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Our-journey.svg";
const Ocassion = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Ocassion.svg";
const Appreciate = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Appreciate.svg";
const Difference = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Difference.svg";
const Win = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Win.svg";
const Facebook = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/optime-facebook.svg";
const Twitter = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/optime-twitter.svg";
const LinkedIn = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/optime-linkedin.svg";
const FooterLine = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Footer-Line.svg";
const ServiceMobileDivider = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Service-mobile-Divider.svg";
const ServiceOne = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/service-one.svg";
const ServiceTwo = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/service-two.svg";
const ServiceThree = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/service-three.svg";
const ServiceFour = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/service-four.svg";
const ExpertiseSeamless = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Expertise-seamless.svg";
const ContactBannerImage = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Contact-Banner.svg";
const FlexBannerNew = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/flex-banner.svg";
const Author = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Author.png";
const NewsSocial = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/news-social.svg";
const BritishMobile = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/British-mobile.svg";
const StarOne = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/stars-one.svg";
const StarTwo = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/stars-two.svg";
const StarThree = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/stars-three.svg";
const StarFour = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/stars-four.svg";
const StarFive = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/stars-five.svg";
const Quotes = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Quotes.svg";
const HeaderServiceOne = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/header-service-one.svg";
const HeaderServiceTwo = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/header-service-two.svg";
const HeaderServiceThree = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/header-service-three.svg";
const HeaderServiceFour = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/header-service-four.svg";
const AboutBanner = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/About-Banner.svg";
const FooterMobile = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/footer-mobile-line.svg";
const FooterTabLine = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Footer-tab.svg";
const PathFinding = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Path-Finding.svg";
const HeathrowContact = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Heathrow.jpg";
const BristolContact = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Bristol.jpg";
const ISS = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/ISS.svg";
const Airport = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Airport.svg";
const UpAway = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/up-away-airport.svg";
const Instagram = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/optime-instagram.svg";
const Step1 = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Step1.svg";
const Step2 = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Step2.svg";
const Step3 = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Step3.svg";
const Step4 = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Step4.svg";
const PictureOne = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Pictureone.png";
const PictureTwo = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Picture-two.jpeg";
const PictureThree = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Picture-three.jpeg";
const PictureFour = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Picture-four.jpeg";
const PictureFive = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Picture-five.jpeg";
const PictureSix = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Picture-six.jpeg";
const ContactBannerTwo = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/contact-banner-two.svg";
const RecruitmentSerivces = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/recruitment-service.svg";
const Manchaster = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/Manchaster.jpg";
const Twitterxlogo = "https://storage.googleapis.com/optime-website-cms.appspot.com/ssr-assets/static/images/twitter-x-logo.svg";

export {
  Logo,
  ServiceArrow,
  FooterMember,
  Newsletter,
  BellIcon,
  BannerBackground,
  BannerFirstPerson,
  BannerSecondPerson,
  DoubleDownArrow,
  BritishAirway,
  Gatwick,
  Easyjet,
  AircraftCleaner,
  BaggageHandler,
  CheckInAgent,
  Line,
  Pin,
  Expertise,
  Mission,
  NewsImage,
  ServicesBanner,
  PuzzleIcon,
  ServiceDivider,
  AirCanada,
  Bristol,
  Belfast,
  DHL,
  // ,Dnata
  Dufry,
  Heathrow,
  London,
  LondonAirport,
  Menzies,
  OCS,
  Restaurant,
  Virginatlantic,
  Wilson,
  FlexBanner,
  FilterImage,
  PlaneIcon,
  ClockIcon,
  BookIcon,
  BookIconBlue,
  StrokeIcon,
  PaymentIcon,
  PlaneIconBlue,
  ClockIconBlue,
  PaymentIconBlue,
  StrokeIconBlue,
  OptimeLeftImage,
  OptimeRightImage,
  OptimeLeftMobile,
  OptimeRightMobile,
  BannerBackgroundMobile,
  TicketDivider,
  DividingLine,
  FlexLogo,
  ContactLogo,
  RecruitmentBanner,
  RecruitmentPermanent,
  RecruitmentTemporary,
  RecruitmentOnSite,
  BackgroudCheckBanner,
  OnlinePortal,
  Candidate,
  TrainingBanner,
  TrainingTeam,
  TrainingWork,
  TrainingOne,
  TrainingTwo,
  TrainingThree,
  TrainingFour,
  TrainingFive,
  TrainingSix,
  TrainingSeven,
  TrainingEight,
  TrainingNine,
  TrainingTen,
  ContactLocation,
  ContactBackground,
  OurJourney,
  Ocassion,
  Win,
  Appreciate,
  Difference,
  Facebook,
  Twitter,
  LinkedIn,
  FooterLine,
  ServiceMobileDivider,
  ServiceThree,
  ServiceOne,
  ServiceTwo,
  ServiceFour,
  ExpertiseSeamless,
  ContactBannerImage,
  FlexBannerNew,
  Author,
  NewsSocial,
  BritishMobile,
  StarOne,
  StarTwo,
  StarThree,
  StarFour,
  StarFive,
  Quotes,
  HeaderServiceOne,
  HeaderServiceTwo,
  HeaderServiceThree,
  HeaderServiceFour,
  AboutBanner,
  FooterMobile,
  FooterTabLine,
  PathFinding,
  HeathrowContact,
  BristolContact,
  ISS,
  Airport,
  UpAway,
  Instagram,
  Step1,
  Step2,
  Step3,
  Step4,
  PictureOne,
  PictureTwo,
  PictureThree,
  PictureFour,
  PictureFive,
  PictureSix,
  ContactBannerTwo,
  RecruitmentSerivces,
  Manchaster,
  Twitterxlogo
};
