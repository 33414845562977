import {
  AboutBanner,
  Appreciate,
  CheckInAgent,
  Difference,
  Expertise,
  ExpertiseSeamless,
  Mission,
  Ocassion,
  OurJourney,
  Win,
} from "../components/UIComponent/images";

export const vettingServicesData = 
  {
    imageContentData: [
      {
        pageName: "",
        bannerLogo: ``,
        title:
          "With our bespoke systems, getting your new hires background checked is a breeze.",
        description:
          "The cost of a bad hire is estimated to cost businesses an average of three times the salary paid. Our background checking process verifies 5-year work history against tax records and comes complete with a criminal record check.",
        src: `${ExpertiseSeamless}`,
        ctaText: "",
        ctaLink: "",
        background: true,
        figureFirst: false,
        banner: false,
      },
      {
        pageName: "",
        bannerLogo: ``,
        title: "Fully compliant systems, trusted by some of the most reputable global brands",
        description:
          "Rest assured you’re in safe hands! Our background check process is ISO 27001 compliant, with security controls in place to safeguard your data from cyber threats.",
        src: `${CheckInAgent}`,
        ctaText: "",
        ctaLink: "",
        background: true,
        figureFirst: false,
        banner: false,
      }
    ],
    ourApproach: {
      title: "Our approach",
      description: "Our highly skilled team and digitised vetting process makes background checks a breeze. With over 30 years of combined experience, we complete referencing services for over 10,000 candidates each year. Our in-house technology helps us achieve unrivalled turnaround times whilst remaining highly compliant."
    },
};
