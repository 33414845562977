import React from "react";
import { Link } from "react-router-dom";
import { handleScrollClick } from "../../utils/scrollHandle";

const CTA = ({ ctaText, ctaLink, contactState, scrollSection }) => {
    return (
        <>
            {scrollSection !== "" && scrollSection !== undefined && scrollSection !== null ? (<button className="cta" onClick={(e) => handleScrollClick(e, scrollSection, 85)}>
                {ctaText}
            </button>) : <Link className="cta" to={{ pathname: ctaLink, state: { data: contactState !== "" ? contactState : null } }} title={ctaText}>
                {ctaText}
            </Link>}
        </>
    )
}

export default CTA;