import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Quotes,
  StarFive,
  StarFour,
  StarOne,
  StarThree,
  StarTwo,
} from "./images";
import { Settings3 } from "./Slider/SliderConstant";

const TrustPilot = (props) => {
  const content = props.trustPilotData;

  return (
    <div className="trustpilot-container">
      <div className="wrapper">
        <h3>What our workers say</h3>
        {/* <div className="content-wrapper"></div> */}
        <div className="slider-container">
          {Array.isArray(content) && content.length ? (
            <Slider {...Settings3}>
              <div className="trustpilot-data-content">
                <div
                  className="trustpilot-widget"
                  data-locale="en-GB"
                  data-template-id="53aa8807dec7e10d38f59f32"
                  data-businessunit-id="5d4fbf228a29290001d40f28"
                  data-style-height="150px"
                  data-style-width="100%"
                  data-theme="light"
                >
                  <a
                    href="https://uk.trustpilot.com/review/optimegroup.co.uk"
                    target="_blank"
                    rel="noopener"
                  >
                    Trustpilot
                  </a>
                </div>
              </div>
              {content.map((items, index) => {
                const { title, review, stars, verified } = items;
                let starImg = "";
                stars === 5
                  ? (starImg = StarFive)
                  : stars === 4
                  ? (starImg = StarFour)
                  : stars === 3
                  ? (starImg = StarThree)
                  : stars === 2
                  ? (starImg = StarTwo)
                  : (starImg = StarOne);

                return (
                  <div key={index}>
                    <figure>
                      <img src={Quotes} />
                    </figure>
                    <h4>{title}</h4>
                    {/* <div className="d-flex justify-content-between star-content">
                      <figure className="stars">
                        <img src={starImg} />
                      </figure>
                      <span className="days">2 days</span>
                    </div> */}
                    <p dangerouslySetInnerHTML={{ __html: review }} />
                    {/* <span className="verified">{verified}</span> */}
                  </div>
                );
              })}
            </Slider>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TrustPilot;
