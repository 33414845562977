import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";


import {
  HomepageBanner,
  SliderContent,
  StayUp,
  FeaturedJob,
  ImageContent,
  NewsList,
  NewsCard,
  TrustPilot,
} from "../../components"; // redux
import {
  newsAPI_Action,
} from "../../stores/actions";
import { HomepageData } from "../../data/homepagedata";

const Hub = (data) => {
  const dispatch = useDispatch();

  const [contentData, setContentData] = useState([])
  // states
  const newsData = useSelector((state) => state.newsData);

  // const listData = newsData.slice(0, 3);
  const { catalog, isFetching, isSuccess } = newsData;
  

  useEffect(() => {
    if (newsData.isFetching === false && newsData.isSuccess === true) {
      let tmpData = Object.entries(newsData.catalog).reverse().slice(0, 3);
      setContentData(tmpData);
  }
  }, [newsData])

  useEffect(() => {
    dispatch(newsAPI_Action());
  }, []);

  const [bannerData, featuredJobData, expertiesData, news, trustPilotData] =
    HomepageData;

  return (
    <div className="homepage">
      <Helmet>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
      </Helmet>
      <HomepageBanner banner={bannerData.banner} />
      <SliderContent />
      <FeaturedJob featuredJobData={featuredJobData.featuredJobData} />
      <ImageContent imageContentData={expertiesData.expertiesData[0]} />
      <TrustPilot trustPilotData={trustPilotData.trustPilotData} />
      <ImageContent imageContentData={expertiesData.expertiesData[1]} />
      <div className="common-news" >
      <div className="wrapper">
        <h3>Optime News</h3>
        {isFetching === false && isSuccess === true ? (
          <ul className="d-flex justify-content-between">

            {contentData.map(([key, values], index) => {
              return values.status === "active" ? ((
                  <li key={index}>
                  {/* <div style={{ opacity: 0 }}>{JSON.stringify(values)}, {index}, KEY: {key}</div> */}
                  <NewsCard newsData={values} newsId={key} />
                  </li>
                )) : null
            })}
          </ul>
        ) : null}
        </div>
      </div>
      {/* <ImageContent missionData={mission.mission} /> */}
      <StayUp />
    </div>
  );
};

export default Hub;
