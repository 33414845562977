import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { handleScrollClick } from "../../utils/scrollHandle";
import Counter from "./Counter";
import FeaturedJob from "./Featured-job";
import {
  BannerBackground,
  BannerBackgroundMobile,
  BannerFirstPerson,
  BannerSecondPerson,
  DoubleDownArrow,
} from "./images";

const HomepageBanner = (props) => {
  const data = props.banner;
  const {
    title,
    description,
    jobcta,
    peoplecta,
    discover,
    countertitle,
    counterdescription,
  } = data;

  const counterScoll = useRef();
  // const handleClick = (e) => {
  //   e.stopPropagation();
  //   e.preventDefault();

  //   let element = document.getElementById("feature-job").offsetTop;
  //   window.scroll(0, element - 50);
  // }

  return (
    <div className="homepage-banner">
      <div className="wrapper banner">
        <div className="content">
          <div className="row d-flex justify-content-between">
            <div className="person-image">
              <figure>
                <img src={BannerFirstPerson} alt="First person" />
              </figure>
            </div>
            <div className=" banner-description">
              <h1
                className="title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <p>{description}</p>
              <ul className="cta-list d-flex justify-content-center">
                <li className="jobs-cta">
                  <a href="https://apply.workable.com/optimegroup/" target="_blank">{jobcta}</a>
                </li>
                <li className="people-cta">
                  <Link to="/recruitment">{peoplecta}</Link>
                </li>
              </ul>
              <div className="discover-more">
                <span>{discover}</span>
                <img
                  src={DoubleDownArrow}
                  onClick={(e) =>
                    handleScrollClick(e, "expertise-thats-miles", -100)
                  }
                />
              </div>
            </div>
            <div className="person-image">
              <figure>
                <img src={BannerSecondPerson} alt="First person" />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div className="counter-wrapper" id="counter-wrapper" ref={counterScoll}>
        <Counter
          countertitle={countertitle}
          counterdescription={counterdescription}
        />
      </div>
      <div className="city">
        <figure>
          <img
            className="banner-desktop"
            src={BannerBackground}
            alt="Banner City"
          />
          <img
            className="banner-mobile"
            src={BannerBackgroundMobile}
            alt="Banner City"
          />
        </figure>
      </div>
    </div>
  );
};

export default HomepageBanner;
