import React from "react";
import { RecruitmentCard } from "../../components";

const RecruitmentOption = (props) => {
  const data = props.recruitmentOption;
  const { title, recruitmentList } = data;

  return (
    <div className="recruitment" id="recruitment">
      <div className="wrapper">
        <h3>{title}</h3>
        {Array.isArray(recruitmentList) ? (
          <ul className="d-flex justify-content-between">
            {recruitmentList.map((items, index) => {
              return <RecruitmentCard content={items} />;
            })}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default RecruitmentOption;
