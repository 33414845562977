import {
  AircraftCleaner,
  BaggageHandler,
  CheckInAgent,
  Expertise,
  Mission,
  NewsImage,
} from "../components/UIComponent/images";

export const HomepageData = [
  {
    banner: {
      title: "Elevating <strong>recruitment solutions</strong> to new heights",
      description:
        "Whether you’re looking to recruit the team you need or searching for jobs to fit your skills, we’re your expert partners at every step of the journey.",
      jobcta: "Looking for JOBS",
      peoplecta: "Looking for PEOPLE",
      discover: "Discover more",
      countertitle: "Trusted by over",
      counterdescription: "applicants and partners worldwide",
    },
  },
  {
    featuredJobData: {
      title: "Explore our featured jobs",
      description:
        "We’re recruiting for some of the most exciting roles in aviation. Apply today and we’ll help you land your next role! ",
      cta: "View all job opportunities",
      cards: [
        {
          src: `${AircraftCleaner}`,
          title: "Aircraft Cleaner",
          location: "London Heathrow",
          shortname: "LHR",
          ctatext: "Apply",
        },
        {
          src: `${BaggageHandler}`,
          title: "Baggage Handler",
          location: "Bristol Airport",
          shortname: "BRS",
          ctatext: "Apply",
        },
        {
          src: `${CheckInAgent}`,
          title: "Check-In Agent",
          location: "London Gatwick",
          shortname: "LGW",
          ctatext: "Apply",
        },
      ],
    },
  },
  {
    expertiesData: [
      {
        pageName: "",
        title: "Expertise that’s <strong>miles ahead</strong> of the curve.",
        description:
          "Dedicated to the aviation sector, our solutions are backed by a team of experts with years of professional experience and in-depth industry knowledge.",
        discoverTitle: "Discover more about:",
        links: [
          {
            linkTitle: "Recruitment",
            linkUrl: "/recruitment",
          },
          {
            linkTitle: "Background Checks",
            linkUrl: "/background-check",
          },
          {
            linkTitle: "Training",
            linkUrl: "/training",
          },
        ],
        src: `${Expertise}`,
        background: true,
        figureFirst: false,
        banner: false,
        scrollId: "expertise-thats-miles",
      },
      {
        title: "Our Mission to help careers <strong>take-off.<strong>",
        description:
          "There’s no place more exciting to propel your career forward than the airport and we’ve been fortunate enough to help thousands of candidates get their aviation journey off to a flying start! Learn more about why we’re so passionate about aviation and what we’re doing to make a real difference to the sector.",
        src: `${Mission}`,
        background: false,
        figureFirst: true,
        banner: false,
        ctaText: "Learn more",
        ctaLink: "/about",
      },
    ],
  },
  {
    news: {
      title: "Optime News",
      newsList: [
        {
          src: `${NewsImage}`,
          newsDate: "November 24 2022",
          newsDescription:
            "Headline of article goes here and can be over two lines",
          newsCtaText: "Read Article",
          newCtaLink: "#",
        },
        {
          src: `${NewsImage}`,
          newsDate: "November 24 2022",
          newsDescription:
            "Headline of article goes here and can be over two lines",
          newsCtaText: "Read Article",
          newCtaLink: "#",
        },
        {
          src: `${NewsImage}`,
          newsDate: "November 24 2022",
          newsDescription:
            "Headline of article goes here and can be over two lines",
          newsCtaText: "Read Article",
          newCtaLink: "#",
        },
      ],
    },
  },
  {
    trustPilotData: [
      {
        title: "Amazing service",
        // stars: 5,
        review:
          "Stared off feeling nervous whilst on my way to my interview but was met with kindness and professionalism from Kirsty. Very happy with my choice to come to this agency and I would highly recommend them. ",
        // verified: "Worldtraveler",
      },
      {
        title: "Very professional ",
        // stars: 5,
        review:
          "I have had a wonderful experience with Amy from Crawley office. She has been very professional and supportive from beginning to end of the recruitment process. She is a true asset to the Optime Group. Can’t wait to start my new role. ",
        // verified: "Worldtraveler",
      },
      {
        title: "Quick Response",
        // stars: 5,
        review:
          "I had really good experience with Optime Group. The team at Optime are friendly, supportive and engaging. I had a quick response to my application and Jenny assisted me with my application she was very friendly and helpful at every stage of the process. Highly recommend Optime Group.",
        // verified: "Worldtraveler",
      },
      {
        title: "Amazing, helpful, effective and supportive",
        // stars: 5,
        review:
          "I would like to thank Jesnie for giving me the opportunity to work for Heathrow airport and for helping me throughout the application process and has always given me fast replies and cleared all my doubts when I had any. She effectively described the job description.",
        // verified: "Worldtraveler",
      },
      {
        title: "Great company",
        // stars: 5,
        review:
          "Applied for a job using Optime Group <br> Had a really fast response and all my questions and queries where answered in no time. big thanks to Amy who was the first person to get to me and point me into the right direction with my application.",
        // verified: "Worldtraveler",
      },
      {
        title: "Fantastic!",
        // stars: 5,
        review:
          "I would recommend those seeking a new opportunity to use Optime Group. They were professional, efficient and friendly. In particular, Cassia was very attentive, friendly and demonstrated excellent customer service throughout and most importantly was able to find me a role in a short space of time! Keep up the good work!",
        // verified: "Worldtraveler",
      },
      {
        title: "Friendly, helpful and attentive!",
        // stars: 5,
        review:
          "Big thanks to Jesnie. She was extremely helpful, made the job much more enjoyable at times of stress and very understanding. Friendly and professional service shown throughout. I would rate more than 5 stars given the choice!",
        // verified: "Worldtraveler",
      },
      {
        title: "The staff in Optime Group are flawless",
        // stars: 5,
        review:
          "I recently got into a very good job with very little effort all thanks to Cassia. It was very simple for me I provided her with all documents she needed and she delt with everything else very good experience and I can only recommend Cassia from Optime Group.",
        // verified: "Worldtraveler",
      },
      {
        title: "My experience was great",
        // stars: 5,
        review:
          "I was happy with the interaction and the outcome. Kirsty was thorough and made everything clear so there was no misunderstandings.",
        // verified: "Worldtraveler",
      },
      {
        title: "Very friendly",
        // stars: 5,
        review:
          "Very friendly warm welcome offered refreshments. Kirsty was very good explaining the role to be honest left there feeling like I want to work for them. Recommend going there you won’t be disappointed.",
        // verified: "Worldtraveler",
      },
    ],
  },
];
