import moment from "moment";
import React, { useEffect, useState } from "react";
import { getDatabase, ref, child, push, update } from "firebase/database";
import { database } from "../../firebase-config";
import { COUNTER_LINK } from "../../config/ApiConstants";

const Counter = (props) => {
  const { countertitle, counterdescription } = props;
  const initVal = "100000";
  const [counterState, setCounterState] = useState([...initVal]);

  useEffect(() => {
    const data = async () => {
      const response = await fetch(COUNTER_LINK);
      const json = await response.json();
      const val = json.toString();
      setCounterState([...val]);
    }
    data();
  }, []);

  return (
    <div className="counter">
      <div className="counter-content">
        <h4>{countertitle}</h4>
        <ul className="d-flex justify-content-center">
          {counterState.map((items, index) => {
            return (
              <li key={index}>
                <span>{items}</span>
              </li>
            );
          })}
        </ul>
        <p>{counterdescription}</p>
      </div>
    </div>
  );
};

export default Counter;
