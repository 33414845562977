import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NewsPage } from "../../data/newsPageData";
import { NewsBanner, NewsList, StayUp } from "../../components";
// redux
import { newsAPI_Action } from "../../stores/actions";

const News = (props) => {
  const [newsBanner] = NewsPage;
  
  const dispatch = useDispatch();
  // const [newsData, setNewsData] = useState([]);

  const newsData = useSelector((state) => state.newsData);

  useEffect(() => {
    dispatch(newsAPI_Action());
  }, []);


  const __apiCall = useCallback(async () => {
    try {
      // await dispatch(newsAPI_Action());
      // console.log("works");
    } catch (error) {
      console.error(" profile ::  profile() :: Exception ==", error);
    }
  });

  return (
    <div className="news">
      {/* <div className="news-list-container"> */}
      <NewsBanner newsData={newsBanner.newsBanner} />
      <NewsList news={newsData} />
      {/* </div> */}
      <StayUp />
    </div>
  );
};

export default News;
