import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Settings1 } from "./SliderConstant.js";
import {
  BritishAirway,
  Gatwick,
  Easyjet,
  DHL,
  Menzies,
  Virginatlantic,
  OCS,
  Wilson,
  Heathrow,
  Restaurant,
  // Dnata,
  Dufry,
  Bristol,
  AirCanada,
  Belfast,
  ISS,
  UpAway,
  London,
} from "../images.js";

const images = [
  {
    src: `${Easyjet}`,
  },
  {
    src: `${DHL}`,
  },
  {
    src: `${BritishAirway}`,
  },
  {
    src: `${Menzies}`,
  },
  {
    src: `${Gatwick}`,
  },
  {
    src: `${Virginatlantic}`,
  },
  {
    src: `${OCS}`,
  },
  {
    src: `${Wilson}`,
  },
  // {
  //   src: `${Heathrow}`,
  // },
  {
    src: `${Restaurant}`,
  },
  // {
  //   src: `${Dnata}`,
  // },
  {
    src: `${ISS}`,
  },
  {
    src: `${Dufry}`,
  },
  {
    src: `${Bristol}`,
  },
  {
    src: `${UpAway}`,
  },
  {
    src: `${AirCanada}`,
  },
  {
    src: `${London}`,
  },
  {
    src: `${Belfast}`,
  },
];

const ContentSlider = () => {
  return (
    <div className="container homepage-slider ">
      <Slider {...Settings1}>
        {images.map((items, index) => {
          return (
            <div key={index}>
              <figure>
                <img src={items.src} />
              </figure>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default ContentSlider;
