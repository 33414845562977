import {
  Expertise,
  Mission,
  RecruitmentBanner,
  FlexLogo,
  RecruitmentPermanent,
  RecruitmentTemporary,
  RecruitmentOnSite,
  ClockIconBlue,
  ContactBannerImage,
  RecruitmentSerivces,
} from "../components/UIComponent/images";

export const RecruitmentData = [
  {
    imageContentData: [
      {
        pageName: "",
        bannerLogo: ``,
        title:
          "When You Need Exceptional People For Your<br> Operations,<strong> The Sky's The Limit.</strong>",
        description:
          "The airport is an exciting and demanding place to work, which is why we’re so passionate about finding candidates who can rise to the challenge and become an asset to your operation.",
        src: `${RecruitmentBanner}`,
        ctaText: "Learn More",
        ctaLink: "/recruitment",
        background: true,
        figureFirst: false,
        banner: true,
        contactState: "",
        scrollSection: "recruitment",
      },
      {
        pageName: "",
        bannerLogo: ``,
        title:
          "<strong> First-Class</strong> Experience,<br> <strong>First-Class</strong> Talent",
        description:
          "Our unique attraction and onboarding process is designed to help staff get into work smoothly and obtain airside clearance at record speeds! We know this makes the role more rewarding for our workforce whilst ensuring maximum value is added to your operation.",
        src: `${RecruitmentSerivces}`,
        ctaText: "Sign Up Now",
        ctaLink: "/contact",
        background: true,
        figureFirst: false,
        banner: false,
        contactState: "recruitment-services"
      },
      {
        pageName: "",
        bannerLogo: ``,
        title: "What makes us<strong> different?</strong>",
        description:
          "As a purpose-driven business, we’re extremely aware of how our recruitment solutions can impact the success and strategic goals of our clients - that’s why our selection process has been meticulously refined to attract and progress first-class aviation talent. <br><br>We pride ourselves on innovation, transparency and data-driven decision-making, consistently sharing our performance through key metrics including attrition, fulfilment and NPS.<br><br>Our partnered approach means we’re ready and available to support all areas of your operation from salary benchmarking to seasonal planning.",
        src: `${ContactBannerImage}`,
        ctaText: "Sign Up Now",
        ctaLink: "/contact",
        background: false,
        figureFirst: true,
        banner: false,
        contactState: "recruitment-services"
      },
    ],
  },
  {
    recruitmentOption: {
      title: "Our Recruitment Services",
      recruitmentList: [
        {
          imageSrc: `${RecruitmentTemporary}`,
          titleImage: `${ClockIconBlue}`,
          listTitle: "Temporary",
          description:
            "We can recruit and deploy 100’s of airside compliant staff to support your operation in various roles such as: Ramp Agents, Passenger Service Agents, PRM, Check-in, Aircraft Cleaning. With our experienced and agile setup – we can usually start supporting immediately.",
          ctaText: "Get in touch",
          ctaLink: "/contact",
          contactState: "recruitment-services"
        },
        {
          imageSrc: `${RecruitmentOnSite}`,
          titleImage: `${ClockIconBlue}`,
          listTitle: "On-Site",
          description:
            "As part of our strategic partnered approach, an on-site presence allows us to take ownership of absence and attendance, daily/weekly reporting as well as regular engagement with colleagues to improve retention.",
          ctaText: "Get in touch",
          ctaLink: "/contact",
          contactState: "recruitment-services"
        },
        {
          imageSrc: `${RecruitmentPermanent}`,
          titleImage: `${ClockIconBlue}`,
          listTitle: "Permanent",
          description:
            "Whether you’re planning to make a couple of strategic hires or looking to significantly increase your FTE, we can support with attracting, onboarding and security clearing all new additions to the team.",
          ctaText: "Get in touch",
          ctaLink: "/contact",
          contactState: "recruitment-services"
        },
      ],
    },
  },
];
