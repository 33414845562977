import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AboutValue, ImageContent, NewsCard, StayUp } from "../../components";
import { FooterLine, FooterMobile } from "../../components/UIComponent/images";
import { aboutData } from "../../data/aboutData";

// redux
import {
  newsAPI_Action,
  resetAPI_Action,
} from "../../stores/actions";

const About = (props) => {
  const [imageContentData, ourValues] = aboutData;

  const dispatch = useDispatch();

  // states
  const newsData = useSelector((state) => state.newsData);

  // const listData = newsData.slice(0, 3);
  const { catalog, isFetching, isSuccess } = newsData;
  let contentData;

  if (isFetching === false && isSuccess === true) {
    contentData = Object.entries(catalog).reverse().slice(0, 3);
  }

  useEffect(() => {
    dispatch(newsAPI_Action());
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
    <div className="about-page">
      <ImageContent imageContentData={imageContentData.imageContentData[0]} />
      <AboutValue valueData={ourValues.ourValues} />
      <ImageContent imageContentData={imageContentData.imageContentData[1]} />
      <div className="dotted-line-container wrapper">
        <figure>
          <img src={FooterLine} className="desktop-line" />
          <img src={FooterMobile} className="mobile-line" />
        </figure>
      </div>
      <ImageContent imageContentData={imageContentData.imageContentData[2]} />
      <div className="common-news" id="news-articles">
        <div className="wrapper">
          <h3>Optime news</h3>
          {isFetching === false && isSuccess === true ? (
            <ul className="d-flex justify-content-between">
              {contentData.map(([key, values], index) => {
              return values.status === "active" ? ((
                  <li key={index}>
                    <NewsCard newsData={values} newsId={index} />
                  </li>
                )) : null
              })}
            </ul>
          ) : null}
        </div>
      </div>
      <StayUp />
    </div>
    </Suspense>
  );
};

export default About;
