import React, { useState } from "react";
import firebase from "firebase/compat/app";
import { getDatabase, ref, set, push, child } from "firebase/database";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "yup-phone";
import moment from "moment";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  mobile: Yup.string()
    .phone(null, true, "Invalid phone number")
    .required("Required"),
  message: Yup.string().max(500).required("Required"),
});

const FormContainer = (props) => {
  const [formState, setFormState] = useState(false);

  const db = getDatabase();
  const newPostKey = push(child(ref(db), 'users')).key;

  return (
    <div className="form-container">
      <Formik
        initialValues={{ email: "", mobile: "", message: "", date: "", reason: props.formState ? props.formState : "contactReason" }}
        validationSchema={SignupSchema}

        onSubmit={(values, { resetForm }) => {

          const date = moment().format("MM/DD/YYYY HH:mm:ss");

          set(ref(db, 'users/' + newPostKey), {
            Email: values.email,
            Mobile: values.mobile,
            Message: values.message,
            Reason: values.reason,
            date: date,
          })

          // setSubmitting(true);
          setFormState(true);

          setTimeout(() => {
            setFormState(false);
          }, 2000)

          resetForm({ values: '' })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                placeholder="Your Email"
              />

              <span className="error-message">
                {errors.email && touched.email && errors.email}
              </span>
            </div>
            <div className="form-group">
              <input
                type="text"
                name="mobile"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mobile}
                placeholder="Your Mobile"
              />
              <span className="error-message">
                {errors.mobile && touched.mobile && errors.mobile}
              </span>
            </div>
            {props.select === true ? (
              <div className="form-group select-group">
                <Field as="select" name="reason">
                  <option value="contactReason">Contact Reason</option>
                  <option value="recruitment-services">Recruitment Services</option>
                  <option value="background-checks">Background Checks</option>
                  <option value="training">Training</option>
                  <option value="optime-flex">Optime Flex</option>
                  <option value="work-for-us">Work for us</option>
                  <option value="general-enquiry">General Enquiry</option>
                </Field>
              </div>
            ) : null}
            <div className="form-group">
              <Field as="textarea" name="message" placeholder="Your Message" />
              <span className="error-message">
                {errors.message && touched.message && errors.message}
              </span>
            </div>
            <div className="submit-wrap">
              <button
                type="submit"
                className="submit-button"
                disabled={isSubmitting}
              >
                Send Message
              </button>
              {formState ? (
                <p className="message">Form has been successfully submitted</p>
              ) : null}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default FormContainer;
