import React from "react";
import {
  ClientMeeting,
  ImageContent,
  StayUp,
  TrainingApproach,
  TrainingCourses,
} from "../../components";
import { FooterLine, FooterMobile } from "../../components/UIComponent/images";
import { trainingData } from "../../data/trainingData";

const Training = () => {
  const [imageContentData, ourApproach, trainingCourses] = trainingData;

  return (
    <div className="training">
      <ImageContent imageContentData={imageContentData.imageContentData[0]} />
      <div className="dotted-line-container wrapper">
        <figure>
          <img src={FooterLine} className="desktop-line" />
          <img src={FooterMobile} className="mobile-line" />
        </figure>
      </div>
      <TrainingApproach ourApproach={ourApproach.ourApproach} />
      <TrainingCourses trainingCourses={trainingCourses.trainingCourses} />
      <ImageContent imageContentData={imageContentData.imageContentData[1]} />
      <ImageContent imageContentData={imageContentData.imageContentData[2]} />
      <ClientMeeting />
      {/* <StayUp /> */}
    </div>
  );
};

export default Training;
