import React from "react";

const ContactCards = (props) => {
  const { title, address, addressLogo, imgSrc, mobile, mobileLogo, option } =
    props.cardData;

  return (
    <li>
      <figure>
        <img src={imgSrc} />
      </figure>
      <h4>{title}</h4>
      <div className="address d-flex justify-content-start align-items-start">
        <img src={addressLogo} alt="pin" />
        <p dangerouslySetInnerHTML={{ __html: address }} />
      </div>
      <div className="call d-flex justify-content-start align-items-start">
        <img src={mobileLogo} />
        <div>
          <a href={`tel:${mobile}`}>{mobile}</a>
          <p>{option}</p>
        </div>
      </div>
    </li>
  );
};

export default ContactCards;
