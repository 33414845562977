import React from "react";
import NewsletterForm from "./Newsletterform";
import { CTA } from "..";

import { Newsletter } from "./images";

const ClientMeeting = () => {
  return (
    <div className="stayup-container">
      <div className="stayup-content client-meeting">
        <div className="content">
          <div className="title-container d-flex justify-content-start align-items-center">
            <h2>Ready to <strong>propel</strong> your operation forward?</h2>
          </div>
          <p>
            We’re excited to learn about your business. Book a virtual meeting with one of our experts today!
          </p>
          <a
            title="Book a meeting"
            href="https://calendly.com/anthony-optimegroup/optime-group?month=2023-02"
            target="_blank"
            className="cta"
          >
            Book a meeting
          </a>
        </div>
        <figure>
          <img src={Newsletter} alt="Newsletter" />
        </figure>
      </div>
    </div>
  );
};

export default ClientMeeting;
