import React, { useEffect } from "react";
import initReactFastclick from "react-fastclick";
import { Header, Footer } from "./components";

import "./assets/css/scss/bootstrap.scss";

const App = (props) => {
  // initialization of fast click
  initReactFastclick();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setTimeout(() => { window.scrollTo(0, 0); }, 100);
    setTimeout(() => {
      const header = document.getElementById("banner-present");
      const banner = document.querySelector(".banner");

      if (banner) {
        if (header) {
          banner.classList.add("banner-exists")
        } else {
          banner.classList.remove("banner-exits");
        }
      }
    }, 1000)

  });





  return (
    <div className="app" id="app">
      <Header />
      <main>
        {props.children}
      </main>
      <Footer />
    </div>
  );
};

export default App;
