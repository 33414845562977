import {
  BristolContact,
  ContactBannerImage,
  ContactBannerTwo,
  ContactLocation,
  ContactLogo,
  FilterImage,
  HeathrowContact,
  Manchaster,
  Pin,
  TrainingTeam,
} from "../components/UIComponent/images";

export const ContactData = [
  {
    contactBanner: {
      imgSrc: `${TrainingTeam}`,
      title:
        "We've got a solid <strong> ground crew</strong> to provide you with <strong>first-class experience</strong>",
      description:
        "Please complete the form below and one of the team will be in touch!",
    },
  },
  {
    contactLocation: {
      title: "Our Locations",
      description:
        "",
      descriptionTwo:
        "",
      locationList: [
        {
          imgSrc: `${ContactLocation}`,
          addressLogo: `${Pin}`,
          mobileLogo: `${ContactLogo}`,
          title: "Crawley (Head office)",
          address:
            "35a High Street,<br> Crawley,<br> West Sussex,<br> RH10 1BQ",
          mobile: "01293 220 928",
          option: "(opt. 1, then 1)",
        },
        {
          imgSrc: `${HeathrowContact}`,
          addressLogo: `${Pin}`,
          mobileLogo: `${ContactLogo}`,
          title: "Heathrow",
          address:
            "Sovereign Court,<br> Sipson Road,<br> West Drayton,<br> UB7 0JE",
          mobile: "01293 220 928",
          option: "(opt. 1, then 2)",
        },
        {
          imgSrc: `${BristolContact}`,
          addressLogo: `${Pin}`,
          mobileLogo: `${ContactLogo}`,
          title: "Bristol",
          address:
            "Aviation House,<br> North Side Road,<br> Bristol Airport,<br> BS48 3AQ ",
          mobile: "01293 220 928",
          option: "(opt. 1, then 3)",
        },
        {
          imgSrc: `${Manchaster}`,
          addressLogo: `${Pin}`,
          mobileLogo: `${ContactLogo}`,
          title: "Manchester",
          address:
            "Adamson House,<br> 106 Wilmslow Road,<br> Didsbury, Manchester,<br> M20 2YY",
          mobile: "01293 220 928",
          option: "(opt. 1, then 4)",
        },
      ],
    },
  },
];
