import {
  AboutBanner,
  Appreciate,
  Difference,
  Expertise,
  Mission,
  Ocassion,
  OurJourney,
  Win,
} from "../components/UIComponent/images";

export const aboutData = [
  {
    imageContentData: [
      {
        pageName: "",
        bannerLogo: ``,
        title:
          "We’re on a mission to become the number one provider of airport staffing solutions, <strong>anywhere in the world.</strong>",
        description:
          "Airport operations are deeply rooted in our foundations, and we take immense pride in making a difference. That’s why our recruitment solutions are innovative and firmly aligned to your goals.",
        src: `${AboutBanner}`,
        ctaText: "",
        ctaLink: "",
        background: true,
        figureFirst: false,
        banner: true,
      },
      {
        pageName: "",
        bannerLogo: ``,
        title: "Our Journey",
        description:
          "Founded in 2016 and headquartered in the UK, Optime was created by a team of industry leaders with an established background and expertise in airport operations.<br><br>This insight lets us understand the significance of performance and accountability. It enables us to approach each client with unrivalled solutions and empower each candidate with reliable pathways and support at every step of the way.<br><br>Our pursuit for sky-high excellence has enabled us to become long-term contributors to our partners and let us grow our team to thousands of live workers.<br><br>We continue to be passionate about making a difference in our industry, so whether you’re looking for expert recruitment solutions, exceptional talent, or an excellent career partner to find your next role, we’ll help you get there - without any turbulence!",
        src: `${OurJourney}`,
        ctaText: "",
        ctaLink: "",
        background: true,
        figureFirst: false,
        banner: false,
      },
      {
        pageName: "",
        bannerLogo: ``,
        title: "Social Responsibility",
        description:
          "We’re serious about being responsible recruiters. We’re ISO 14001 accredited to ensure we’re constantly monitoring and improving our environmental impact. We also like to give back and regularly sponsor local charitable causes – keep up with our latest activities through our news section!",
        src: `${Mission}`,
        ctaText: "Learn more",
        ctaLink: "/news",
        background: false,
        figureFirst: true,
        banner: false,
      },
    ],
  },
  {
    ourValues: {
      title: "Our Values",
      description:
        "These values have shaped our business from the day we started and continue to steer us today. They are something we bring to life in everything we do, whether that’s supporting clients, helping candidates or working on our next big innovation.",
      valuesList: [
        {
          title: "Rise to the Occasion",
          imgSrc: `${Ocassion}`,
        },
        {
          title: "Appreciate Each Other",
          imgSrc: `${Appreciate}`,
        },
        {
          title: "Make A Difference",
          imgSrc: `${Difference}`,
        },
        {
          title: "Win Together",
          imgSrc: `${Win}`,
        },
      ],
    },
  },
];
