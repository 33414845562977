import {
  ServicesBanner,
  Expertise,
  PuzzleIcon,
  AircraftCleaner,
  AirCanada,
  Bristol,
  BritishAirway,
  Belfast,
  DHL,
  // Dnata,
  Dufry,
  Easyjet,
  Heathrow,
  London,
  LondonAirport,
  Menzies,
  OCS,
  Restaurant,
  Virginatlantic,
  Wilson,
  ServiceOne,
  ServiceTwo,
  ServiceThree,
  ServiceFour,
  PathFinding,
} from "../components/UIComponent/images";



// TODO: dnata logo is removed from the list of logos due to error in file 
export const ServicesData = [
  {
    imageContentData: [
      {
        pageName: "",
        title:
          "<strong>Ground-breaking</strong> solutions, tailored to your objectives",
        description:
          "Driven by our passion for airport operations, we take pride in making a difference which is why our recruitment solutions align to your most important goals.",
        src: `${ServicesBanner}`,
        ctaText: "Learn More",
        ctaLink: "#",
        background: true,
        figureFirst: false,
        banner: true,
        scrollSection: "service-list",
      },
      {
        pageName: "",
        title:
          "<strong>Pathfinding solutions</strong> centred on innovation and data.",
        description:
          "Attracting, retaining and security clearing 1000’s of staff every year requires a strategic and innovative approach. Our refined process has made us the partner of choice for some of the largest and most successful operators in aviation.",
        src: `${PathFinding}`,
        ctaText: "",
        ctaLink: "",
        background: true,
        figureFirst: false,
        banner: false,
      },
    ],
  },
  {
    ourServices: {
      title: "Our Services",
      servicesList: [
        {
          title: "Recruitment",
          description:
            "Our bespoke attraction methods help us connect our clients with exceptional staff for their airport operations.",
          ctaText: "Find out more",
          ctaLink: "/recruitment",
          imgSrc: `${ServiceOne}`,
          iconSrc: `${PuzzleIcon}`,
        },
        {
          title: "Background Checks",
          description:
            "Get your workforce runway ready in speedy fashion with a trusted team of experts who hold signatory status at all major UK airports.",
          ctaText: "Find out more",
          ctaLink: "/background-check",
          imgSrc: `${ServiceFour}`,
          iconSrc: `${PuzzleIcon}`,
        },
        {
          title: "Training",
          description:
            "We offer a full range of below-wing courses to support your onboarding and refresher training regime.",
          ctaText: "Find out more",
          ctaLink: "/training",
          imgSrc: `${ServiceTwo}`,
          iconSrc: `${PuzzleIcon}`,
        },
        // {
        //   title: "Flex",
        //   description:
        //     "Optime Flex gives you access to a large, airside compliant workforce who are ready to book through our dedicated app.",
        //   ctaText: "Find out more",
        //   ctaLink: "/flex",
        //   imgSrc: `${ServiceThree}`,
        //   iconSrc: `${PuzzleIcon}`,
        // },
      ],
    },
  },
  {
    brandsData: {
      title: "Brands we work with",
      brandsLogo: [
        // `${Heathrow}`,
        `${DHL}`,
        `${Restaurant}`,
        // `${Dnata}`,
        `${Dufry}`,
        `${Bristol}`,
        `${Belfast}`,
        `${AirCanada}`,
        `${LondonAirport}`,
        `${Wilson}`,
        `${BritishAirway}`,
        `${Menzies}`,
        `${London}`,
        `${Virginatlantic}`,
        `${OCS}`,
        `${Easyjet}`,
      ],
      brandsLogoMobile: [
        [`${DHL}`, `${Restaurant}`, 
        // `${Dnata}`,
         `${Dufry}`],
        [`${Bristol}`, `${Belfast}`, `${AirCanada}`, `${LondonAirport}`],
        [`${Wilson}`, `${BritishAirway}`, `${Menzies}`, `${London}`],
        [`${Virginatlantic}`, `${OCS}`, `${Easyjet}`],
      ],
    },
  },
];
