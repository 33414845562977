import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Settings2 } from "./SliderConstant";

const CandidateSlider = (props) => {
  const candidateList = props.candidateList;

  return (
    <>
      <Slider {...Settings2}>
        {candidateList.map((items, index) => {
          const {
            candidateImg,
            brandLogo,
            brandMobile,
            quotes,
            candidateName,
            candidateDesignation,
          } = items;

          return (
            <div
              // className="d-flex justify-content-between"
              // style={{
              //   padding: "20px",
              //   display: "block",
              //   flexDirection: "column",
              // }}
              key={index}
              className="content"
            >
              <figure
              // style={{ width: "40%", display: "inline-block" }}
              >
                <img
                  // style={{ width: "100%" }}
                  src={candidateImg}
                />
              </figure>
              <div
                className="candidate-content"
                // style={{ width: "55%", display: "inline-block" }}
              >
                <figure>
                  <img className="mobile" src={brandMobile} />

                  <img
                    className="desktop"
                    src={brandLogo}

                    // style={{ width: "100%" }}
                  />
                </figure>
                <h4>{quotes}</h4>
                <span className="name">{candidateName}</span>
                <span className="designation">{candidateDesignation}</span>
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
};

export default CandidateSlider;
