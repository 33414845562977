import React from "react";
import { CTA } from "..";

const OnlinePortalContainer = (props) => {
  console.log(props);

  const { title, description, ctaText, ctaLink, imgSrc } =
    props.onlinePortalData;

  return (
    <div className="online-portal">
      <div className="wrapper">
        <h3>{title}</h3>
        <p dangerouslySetInnerHTML={{ __html: description }} />
        <figure>
          <img src={imgSrc} />
        </figure>
        {/* <CTA ctaText={ctaText} ctaLink={ctaLink} /> */}
      </div>
    </div>
  );
};

export default OnlinePortalContainer;
