export const Settings1 = {
  infinite: true,
  dots: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  lazyLoad: true,
  autoplay: true,
  autoplaySpeed: 6000,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

export const Settings2 = {
  infinite: true,
  arrows: true,
  dots: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000,
  centerMode: true,
  centerPadding: "60px",
  variableWidth: true,

  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        centerPadding: "20px",
      },
    },
  ],
};

export const Settings3 = {
  infinite: true,
  dots: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  lazyLoad: true,
  autoplay: true,
  arrows: false,
  autoplaySpeed: 6000,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
