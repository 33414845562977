import React from "react";
import { ServiceDivider, ServiceMobileDivider } from "./images";
import { CTA } from "..";

const ServiceCard = (props) => {
  const { title, description, ctaText, ctaLink, imgSrc, iconSrc } = props.data;

  return (
    <li className="services-card d-flex">
      <div className="content">
        <div className="title-container d-flex align-items-center">
          <img className="m-l-5" src={iconSrc} />
          <h4>{title}</h4>
        </div>
        <p>{description}</p>
        <CTA ctaText={ctaText} ctaLink={ctaLink} />
      </div>
      <figure className="divider">
        <img src={ServiceMobileDivider} className="mobile-divider" />
        <img src={ServiceDivider} className="desktop-divider" />
      </figure>
      <figure className="service-image">
        <img src={imgSrc} />
      </figure>
    </li>
  );
};

export default ServiceCard;
