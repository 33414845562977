import React from "react";
import { ClientMeeting, ImageContent, RecruitmentOption, StayUp } from "../../components";
import { RecruitmentData } from "../../data/recruitmentData";

const Recruitment = () => {
  const [imageContentData, recruitmentOption] = RecruitmentData;

  return (
    <div className="recruitment-page">
      <ImageContent imageContentData={imageContentData.imageContentData[0]} />
      <RecruitmentOption
        recruitmentOption={recruitmentOption.recruitmentOption}
      />
      <ImageContent imageContentData={imageContentData.imageContentData[1]} />
      <ImageContent imageContentData={imageContentData.imageContentData[2]} />
      <ClientMeeting />
    </div>
  );
};

export default Recruitment;
