export const NEWS_REQUEST = "NEWS_REQUEST";
export const NEWS_RECEIVE = "NEWS_RECEIVE";
export const NEWS_FAILURE = "NEWS_FAILURE";
export const RESET_NEWS = "RESET_NEWS";


export const NEWS_DETAILS_REQUEST = "NEWS_DETAILS_REQUEST";
export const NEWS_DETAILS_RECEIVE = "NEWS_DETAILS_RECEIVE";
export const NEWS_DETAILS_FAILURE = "NEWS_DETAILS_FAILURE";
export const RESET_NEWS_DETAILS = "RESET_NEWS_DETAILS";
