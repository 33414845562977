export const ENVIRONMENT = 'production' // process.env.REACT_APP_ENV;
export const APP_VERSION = '0.0.1' // process.env.REACT_APP_VERSION;
// development links 
let api_endpoint = 'https://optimewebpa-default-rtdb.firebaseio.com/' // process.env.REACT_APP_API_ENDPOINT;
let auth_headers = { 'X-Authorization': 'AIzaSyBK9jlLsDfdAQqPh2Hq9K3BiqcI9IRoVNI', 'Content-Type': 'application/json' };
let counter_link = "https://optimewebpa-default-rtdb.firebaseio.com/trustedOverByCount.json";
let topbannerlink = "https://optimewebpa-default-rtdb.firebaseio.com/bannertext.json"

if (ENVIRONMENT === 'production') {
    api_endpoint = 'https://optime-website-cms-default-rtdb.firebaseio.com/' // process.env.REACT_APP_API_ENDPOINT;
    auth_headers = { 'X-Authorization': 'AIzaSyCb-vidgaFGxgYQ2kq51jVVWe-gIPgyCs4', 'Content-Type': 'application/json' };
    counter_link = "https://optime-website-cms-default-rtdb.firebaseio.com/trustedOverByCount.json";
    topbannerlink = "https://optime-website-cms-default-rtdb.firebaseio.com/bannertext.json";
}

export const API_ENDPOINT = api_endpoint;
export const AUTH_HEADERS = auth_headers;
export const COUNTER_LINK = counter_link;
export const TOPBANNERLINK = topbannerlink;