import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Settings1 } from "./Slider/SliderConstant";

const Brands = (props) => {
  const { title, brandsLogo, brandsLogoMobile } = props.brandsData;

  function getWindowSize() {
    const { innerWidth } = window;
    return { innerWidth };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  console.log(windowSize);

  return (
    <section className="brands">
      <div className="wrapper">
        <h3>{title}</h3>
        {Array.isArray(brandsLogo) && brandsLogo.length ? (
          <>
            {windowSize.innerWidth >= 768 ? (
              <div className="logo-container d-flex justify-content-center">
                <ul className="d-flex justify-content-center">
                  {brandsLogo.map((logos, logoindex) => {
                    return (
                      <li key={logoindex}>
                        <figure>
                          <img src={logos} />
                        </figure>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <Slider {...Settings1}>
                {brandsLogoMobile.map((items, index) => {
                  return (
                    <ul className="slider-list" key={index}>
                      {items.map((logos, logoindex) => {
                        return (
                          <li key={logoindex}>
                            <figure>
                              <img src={logos} />
                            </figure>
                          </li>
                        );
                      })}
                    </ul>
                  );
                })}
              </Slider>
            )}
          </>
        ) : null}
      </div>
    </section>
  );
};

export default Brands;
