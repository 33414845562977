import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

import { FacebookIcon, LinkedinIcon, TwitterIcon } from "react-share";
import { Twitterxlogo } from "./images";
const SocialShare = ({ url, title }) => {

  // url integration for linkedin share 
  const linkedInURl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
  // console.log(linkedInURl);


  return (
    <div className="social d-flex justify-content-center">
      <FacebookShareButton url={url} title={title} className="mr-2">
        <FacebookIcon size={36} round />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title} className="mr-2">
        {/* <TwitterIcon size={36} round /> */}

        <img src={Twitterxlogo} />
      </TwitterShareButton>
      {/* <LinkedinShareButton url={url}>
            <LinkedinIcon size={36} round />
        </LinkedinShareButton> */}
    </div>
  );
};
export default SocialShare;