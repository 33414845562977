import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { SocialShare, StayUp } from "../../components";
import { Author, NewsSocial } from "../../components/UIComponent/images";
import { newsDetails_Action } from "../../stores/actions";
import { ShareSocial } from "react-share-social";
import { Helmet } from "react-helmet";

const NewsDetail = (props) => {

  let location = useLocation();
  const dispatch = useDispatch();

  const newsDetails = useSelector((state) => state.newsDetailsData);
  const [newsDetailData, setNewsDetailData] = useState(null);

  const url = 'window.location.href';

  useEffect(() => {
    // if(!location.state) {
    let pathName = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    dispatch(newsDetails_Action(pathName))
    // }
  }, []);

  useEffect(() => {
    if (newsDetails.isSuccess === true && newsDetails.catalog !== null) {
      setNewsDetailData(newsDetails.catalog);
    } else if (newsDetails.isError === true) {
      setNewsDetailData(newsDetails);
    }
  }, [newsDetails]);


  const __renderHeaders = () => {
    return <Helmet>
      <meta property="og:image" key="og:image" content={newsDetailData?.imageUrl} />
      <meta property="og:title" key="og:title" content={newsDetailData.title} />
      <meta name="description" content={newsDetailData.description && newsDetailData.description.substring(0, 100) + "..."} />

      <meta property="og:description" key="og.description" content={newsDetailData.description && newsDetailData.description.substring(0, 100) + "..."} />
      <meta name="twitter:card" content={newsDetailData.title} />
      <meta name="twitter:image:src" content={newsDetailData?.imageUrl} />
      <title>{newsDetailData.title}</title>
    </Helmet>
  }

  return (
    <>
      {newsDetails.isFetching ? <div className="news-loader"><h1>Loading...</h1></div> :
        <div className="news-details">

          {/* {__renderHeaders()} */}
          <div className="wrapper banner">
            <h2 className="title">{newsDetailData?.title}</h2>
            <div className="author-container">
              <figure>
                <img src={Author} alt={newsDetailData?.authorName} title={newsDetailData?.authorName} />
              </figure>
              <div className="author-detail">
                <span className="name">{newsDetailData?.authorName}</span>
                <span className="designation">{newsDetailData?.authorDesignation}</span>
              </div>
            </div>
            <p className="date">{moment(newsDetailData?.publishDate).format("MMMM-D-YYYY")}</p>
            <figure className="news-banner-image">
              <img src={newsDetailData?.imageUrl} alt={newsDetailData?.title} title={newsDetailData?.authorName} />
            </figure>
            <div className="description">
              <div dangerouslySetInnerHTML={{ __html: newsDetailData?.description }} />
            </div>
            <SocialShare url={url} title={newsDetailData?.title} />
          </div>
        </div>
      }
      <StayUp />
    </>
  );
};

export default NewsDetail;