import React from "react";
import { ImageContent, FilterComponent, FlexSignUp, ClientMeeting } from "../../components";
import { flexData } from "../../data/flexData";

const Flex = (props) => {
  const  [imageContentData, filterData, flexSignup ] = flexData;
  
  return (
    <div>
      {/* <div className="col-md-6 col-lg-6">
        <div>
          <br />
          <h3>Flex</h3>
          <br />
        </div>
      </div> */}
      <ImageContent imageContentData={imageContentData.imageContentData[0]} />
      <FilterComponent filterData={filterData.filterData} />
      <FlexSignUp flexSignupData={flexSignup.flexSignup} />
      <ClientMeeting />
    </div>
  );
};

export default Flex;
