import React  from "react";
import { useLocation } from "react-router-dom";
import { ContactBanner, ContactList } from "../../components";
import { ContactData } from "../../data/contactData";

const Contact = (props) => {
  const [contactBanner, contactLocation] = ContactData;

  const location = useLocation();
  const contactFormState = location.state !== null && location.state !== undefined ? location.state.data : null;

  return (
    <div className="Contact">
      <ContactBanner banner={contactBanner.contactBanner}  formState={contactFormState} />
      <ContactList contactList={contactLocation.contactLocation} />
    </div>
  );
};

export default Contact;
