import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';


export function newsAPI_Action() {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}news.json`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS
            },
            body: '',
            types: [
                types.NEWS_REQUEST,
                types.NEWS_RECEIVE, {
                    type: types.NEWS_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetnewsAPI_Action = () => {
    return {
        type: types.RESET_NEWS,
        payload: null
    }
};



export function newsDetails_Action(newsId) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}news/${newsId}.json`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS
            },
            body: '',
            types: [
                types.NEWS_DETAILS_REQUEST,
                types.NEWS_DETAILS_RECEIVE, {
                    type: types.NEWS_DETAILS_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const resetNewsDetails_Action = () => {
    return {
        type: types.RESET_NEWS_DETAILS,
        payload: null
    }
};
