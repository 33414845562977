import {
  TrainingBanner,
  TrainingOne,
  TrainingTeam,
  TrainingWork,
  TrainingTwo,
  TrainingThree,
  TrainingFour,
  TrainingFive,
  TrainingSix,
  TrainingSeven,
  TrainingEight,
  TrainingNine,
  TrainingTen,
  Airport,
} from "../components/UIComponent/images";

export const trainingData = [
  {
    imageContentData: [
      {
        pageName: "",
        bannerLogo: ``,
        title: "Training that's <strong>miles</strong> ahead of the curve",
        description:
          "Our in-house training team is ready to upskill your airport workforce with a variety of below-wing courses.",
        src: `${TrainingBanner}`,
        ctaText: "Learn More",
        ctaLink: "#",
        background: true,
        figureFirst: false,
        banner: true,
        contactState: "",
        scrollSection: "our-approach"
      },
      {
        pageName: "",
        bannerLogo: ``,
        title: "Elevate your skillset at multiple locations across the UK",
        description:
          "As recognised training operators we can deliver airside training at multiple locations including Gatwick, Heathrow, and Stansted airports. To find out more about our upcoming course schedule or to discuss any bespoke requirements, please get in touch.",
        src: `${Airport}`,
        ctaText: "Get in touch",
        ctaLink: "/contact",
        background: true,
        figureFirst: false,
        banner: false,
        contactState: "training"
      },
      {
        pageName: "",
        bannerLogo: ``,
        title: "We’ve got a solid <strong>ground crew</strong> to provide a <strong>first-class</strong> service.",
        description:
          "Our training team has over 30 years’ experience in delivering the safest and highest quality below-wing courses in aviation. That’s why we’re trusted to train some of the largest and most successful workforces across the airport.",
        src: `${TrainingTeam}`,
        ctaText: "Get in touch",
        ctaLink: "/contact",
        background: false,
        figureFirst: true,
        banner: false,
        contactState: "training"
      },
    ],
  },
  {
    ourApproach: {
      title: "Our Approach",
      description:
        "Our mission is to be the number one provider of airport staffing solutions anywhere in the world and our training offering helps us fully realise this. Not only can we recruit and airside clear the best staff, we can upskill them too!",
    },
  },
  {
    trainingCourses: {
      title: "Training Courses",
      description:
        "For bespoke solutions or to learn more about our complete offering, please get in touch.",
      ctaText: "Get in touch",
      ctaLink: "/contact",
      contactState: "training",
      coursesList: [
        {
          title: "Dangerous Goods",
          imgSrc: `${TrainingOne}`,
        },
        {
          title: "Headset",
          imgSrc: `${TrainingTwo}`,
        },
        {
          title: "Chocks and Cones",
          imgSrc: `${TrainingThree}`,
        },
        {
          title: "Airside Safety",
          imgSrc: `${TrainingFour}`,
        },
        {
          title: "Steps",
          imgSrc: `${TrainingFive}`,
        },
        {
          title: "EBT",
          imgSrc: `${TrainingSix}`,
        },
        {
          title: "Belt loader",
          imgSrc: `${TrainingSeven}`,
        },
        {
          title: "Pushback",
          imgSrc: `${TrainingEight}`,
        },
        {
          title: "PRM",
          imgSrc: `${TrainingNine}`,
        },
        {
          title: "Customer Service",
          imgSrc: `${TrainingTen}`,
        },
      ],
    },
  },
];
