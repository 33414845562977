import React from "react";
import CTA from "./cta";

const TrainingCourses = (props) => {
  const { title, description, ctaText, ctaLink, coursesList,contactState } =
    props.trainingCourses;

  return (
    <div className="training-courses" >
      <div className="wrapper">
        <h3>{title}</h3>
        <p>Our list of aerodrome-approved courses includes:</p>
        {Array.isArray(coursesList) && coursesList.length ? (
          <ul className="d-flex justify-content-center courses-list align-items-center">
            {coursesList.map((items, index) => {
              return (
                <li key={index}>
                  <div className="d-flex justify-content-between align-items-center">
                    <figure>
                      <img src={items.imgSrc} />
                    </figure>
                    <h4>{items.title}</h4>
                  </div>
                </li>
              );
            })}
          </ul>
          
        ) : null}
        <p>{description}</p>
          <CTA ctaText={ctaText} ctaLink={ctaLink} contactState={contactState} />
      </div>
    </div>
  );
};

export default TrainingCourses;
