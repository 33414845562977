import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { NewsCard } from "..";
import Loader from "./Loader";
import moment from "moment";

const NewsList = (props) => {
  const { catalog, isFetching, isSuccess } = props.news;
  const categoryList = [];
  const [categoryState, setCategoryState] = useState("All categories");
  const [dateState, setDateState] = useState("Newest first");
  let data;

  // const [dateContent, setDateContent] = useState([]);

  if (isFetching === false && isSuccess === true) {
    const dateArray = [];
    const arraySort = Object.entries(catalog);
    arraySort.map(([items, values], index) => {
      values['id'] = items;
      dateArray.push(values);
    });

    let newDateArray = dateArray.sort((a, b) => {
      return new Date(a.publishDate) - new Date(b.publishDate);

    });

    if (dateState === "Newest first") {
      data = newDateArray.reverse();
    } else {
      data = newDateArray;
    }
  }

  useEffect(() => { }, [categoryState]);

  return (
    <section className="news-list">
      <div className="wrapper">
        {isFetching === false && isSuccess === true ? (
          <>
            {Object.values(catalog).map((items, index) => {
              let category = items.category;
              if (!categoryList.includes(category)) {
                categoryList.push(category);
              }
            })}

            {/* code to create the dropdown  */}
            <div className="news-filter-container d-flex justify-content-between">
              {categoryList.length !== 0 ? (
                <div className="select-wrap">
                  <select
                    value={categoryState}
                    onChange={(e) => setCategoryState(e.target.value)}
                  >
                    <option>All categories</option>
                    <option value="companyNews">Company News</option>
                    <option value="industryNews">Industry News</option>
                    {/* {categoryList.map((option, index) => {
                      let data = option === "companyNews" ? "Company News" : option === "industryNews"
                        ?  "Industry News" : option
                      return <option key={index} value={option}>
                        
                        {data}</option>;
                    })} */}
                  </select>
                </div>
              ) : null}
              <div className="select-wrap">
                <select
                  value={dateState}
                  onChange={(e) => setDateState(e.target.value)}
                >
                  <option>Newest first</option>
                  <option>Oldest first</option>
                </select>
              </div>
            </div>

            <ul className="d-flex justify-content-start">
              {data.map((values, index) => {
                let id = index;

                return categoryState === "All categories" && values.status === "active" ? (
                  <li key={index}>
                    <NewsCard newsData={values} newsId={values.id} />
                  </li>
                ) : categoryState === values.category && values.status === "active" ? (
                  <li key={index}>
                    <NewsCard newsData={values} newsId={values.id} />
                  </li>
                ) : (
                  <div className="news-loader"><h1>No posts found</h1></div>
                );
              })}
            </ul>
          </>
        ) : (
          <div className="news-loader"><h1>Loading...</h1></div>
        )}
      </div>
      <Pagination />
    </section>
  );
};

export default NewsList;
