import React from "react";
import { CandidateSlider } from "..";
import CTA from "./cta";

const Candidate = (props) => {
  const { title, description, candidatesList, ctaText, ctaLink } =
    props.candidateData;

  return (
    <div className="candidate-section">
      <h3 dangerouslySetInnerHTML={{ __html: title }} />
      {Array.isArray(candidatesList) && candidatesList.length ? (
        <CandidateSlider candidateList={candidatesList} />
      ) : null}
      {/* <div className="content">
        <p className="description">{description}</p>
        <CTA ctaText={ctaText} ctaLink={ctaLink} />
      </div> */}
    </div>
  );
};

export default Candidate;
