import React, { useState, useEffect } from "react";
import {
  HeaderServiceFour,
  HeaderServiceOne,
  HeaderServiceThree,
  HeaderServiceTwo,
  Logo,
  ServiceArrow,
} from "./images";
import { TOPBANNERLINK } from "../../config/ApiConstants";

import { Link, NavLink } from "react-router-dom";
import CTA from "./cta";
import {
  Button,
  Navbar,
  Nav,
  Form,
  FormControl,
  NavItem,
  NavDropdown,
  MenuItem,
} from "react-bootstrap";

const Header = (props) => {
  // let history = useHistory();
  // const redirectUrl = (e) => {
  //   history.push(e);
  // };

  const [menuOpen, setMenuOpen] = useState(false);
  const [mobileServiceMenu, setMobileServiceMenu] = useState(false);
  const [handleDropDown, sethandleDropDown] = useState(false);
  const [topMessage, setTopMessage] = useState(false);
  const [topBannerText, setTopBannerText] = useState("");

  const handleClick = () => {
    setMenuOpen(false);
    setMobileServiceMenu(false);
    sethandleDropDown(false);
  };

  const dropDownHandle = (e) => {
    e.stopPropagation();
    setMobileServiceMenu(true);
    sethandleDropDown(!handleDropDown);
    setMobileServiceMenu(!mobileServiceMenu);
  }

  useEffect(() => {
    const data = async () => {
      const response = await fetch(TOPBANNERLINK);
      const json = await response.json();
      setTopMessage(json.topBanner);
      setTopBannerText(json.bannerText);
    }
    data();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        const header = document.getElementById("banner-present");
        const topBannerHeight = document.querySelector(".top-banner");
        const navMenus = document.querySelector(".navbar-collapse");
        if (header && topBannerHeight) {
          navMenus.style.top = topBannerHeight.clientHeight + 60 + "px";
        } else {
          navMenus.style.top = "59px";
        }

      })
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  }, [topMessage])


  return (
    <>
      <header className={menuOpen ? "menu-open" : "menu-close"} id={topMessage && topBannerText !== "" ? "banner-present" : null}>
        {topMessage && topBannerText !== "" ? (
          <div className="top-banner">
            <p>
              {topBannerText}
            </p>

            <div className="cross" onClick={() => setTopMessage(false)}>
              <span>1</span>
              <span>2</span>
            </div>
          </div>
        ) : null}
        <Navbar variant="dark">
          <div className="d-flex align-items-center sticky-banner">
            <Link to="/" className="nav-brand mr-3 " onClick={() => handleClick()}>
              <img src={Logo} alt="Optime Logo" />
            </Link>
            {/* TrustBox widget - Micro Combo  */}
            <div className="trustpilot-widget" data-locale="en-GB" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5d4fbf228a29290001d40f28" data-style-height="20px" data-style-width="100%" data-theme="dark">
              <a href="https://uk.trustpilot.com/review/optimegroup.co.uk" target="_blank" rel="noopener">Trustpilot</a>
            </div>
            {/* End TrustBox widget  */}
          </div>
          <button
            className="hamburger"
            type="button"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span className="navbar-toggler-icon-close"></span>
            <span className="navbar-toggler-icon-close"></span>
            <span className="navbar-toggler-icon-close"></span>
          </button>

          <div
            className={
              topMessage
                ? "navbar-collapse banner-visible collapse show p-0"
                : "navbar-collapse banner-exists collapse show p-0"
            }
          >
            <ul className="d-flex  justify-content-end navbar-nav ml-auto navbar-mobile">
              <li className="services-link">
                <div className="d-flex jusify-content-between">
                  <NavLink
                    to="/services"
                    className="nav-link"
                    onClick={() => handleClick()}
                  >
                    Services
                  </NavLink>
                  <img
                    className={
                      mobileServiceMenu ? "arrow rotate" : "arrow rotate-off"
                    }
                    src={ServiceArrow}
                    // onClick={() => setMobileServiceMenu(!mobileServiceMenu)}
                    onClick={(e) => dropDownHandle(e)}
                  />
                </div>
                <ul
                  className={
                    mobileServiceMenu ? "mobile-service show" : "mobile-service"
                  }
                >
                  <li>
                    <NavLink to="/recruitment" onClick={() => handleClick()}>
                      Recruitment Services
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/background-check" onClick={() => handleClick()}>
                      Background Checks
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/training" onClick={() => handleClick()}>
                      Training
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="flex" onClick={() => handleClick()}>
                      Flex
                    </NavLink>
                  </li> */}
                </ul>
                <ul className={handleDropDown ? "d-flex dropdown-list justify-content-around open-dropdown" : "d-flex dropdown-list justify-content-around close-dropdown"}>
                  <li>
                    <figure>
                      <img src={HeaderServiceOne} />
                    </figure>
                    <h5>Recruitment Services</h5>
                    <NavLink to="/recruitment" className="cta" onClick={() => handleClick()} >Find out more</NavLink>
                  </li>
                  <li>
                    <figure>
                      <img src={HeaderServiceFour} />
                    </figure>
                    <h5>Background Checks</h5>
                    <NavLink to="/background-check" className="cta" onClick={() => handleClick()}>Find out more</NavLink>
                  </li>
                  <li>
                    <figure>
                      <img src={HeaderServiceTwo} />
                    </figure>
                    <h5>Training</h5>
                    <NavLink to="/training" className="cta" onClick={() => handleClick()}>Find out more</NavLink>
                  </li>
                  {/* <li>
                    <figure>
                      <img src={HeaderServiceThree} />
                    </figure>
                    <h5>Flex</h5>
                    <NavLink to="/flex" className="cta" onClick={() => handleClick()}>Find out more</NavLink>
                  </li> */}
                </ul>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  to="/news"
                  onClick={() => handleClick()}
                >
                  News
                  <img className="arrow" src={ServiceArrow} />
                </NavLink>
              </li>
              {/* <Nav.Link onClick={(e) => redirectUrl("/news")}>News</Nav.Link> */}

              {/* <Nav.Link onClick={(e) => redirectUrl("/flex")}>flex</Nav.Link> */}
              {/* <li>
                <NavLink
                  className="nav-link"
                  to="/flex"
                  onClick={() => handleClick()}
                >
                  Flex
                  <img className="arrow" src={ServiceArrow} />
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  className="nav-link"
                  to="/about"
                  onClick={() => handleClick()}
                >
                  About
                  <img className="arrow" src={ServiceArrow} />
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  to="/contact"
                  onClick={() => handleClick()}
                >
                  Contact
                  <img className="arrow" src={ServiceArrow} />
                </NavLink>
              </li>
              <li>
                <a className="cta" target="_blank" href="https://apply.workable.com/optimegroup/">Explore Jobs</a>
                {/* <Link
                to="www.google.com"
                title="explore jobs"
                className="explore-job"
              >
                Explore jobs
              </Link> */}
              </li>
              {/* <Nav.Link onClick={(e) => redirectUrl("/about")}>about</Nav.Link> */}

              {/* <Nav.Link onClick={(e) => redirectUrl("/contact")}>
              contact
            </Nav.Link> */}
            </ul>
          </div>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
