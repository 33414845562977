import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AboutValue, ImageContent, NewsCard, StayUp, TrainingApproach } from "../../components";
import { FooterLine, FooterMobile } from "../../components/UIComponent/images";
import { vettingServicesData } from "../../data/vettingServicesData";



const VettingServices = (props) => {
  const { imageContentData, ourApproach } = vettingServicesData;
  
  // states
  const newsData = useSelector((state) => state.newsData);

  // const listData = newsData.slice(0, 3);
  const { catalog, isFetching, isSuccess } = newsData;
  let contentData;

  if (isFetching === false && isSuccess === true) {
    contentData = Object.entries(catalog).reverse().slice(0, 3);
  }


  return (
    <Suspense fallback={<div>Loading...</div>}>
    
      <ImageContent imageContentData={imageContentData[0]} />
      <TrainingApproach ourApproach={ourApproach} />
      <ImageContent imageContentData={imageContentData[1]} />
      
      
      <StayUp />
    
    </Suspense>
  );
};

export default VettingServices;
