import {
  BackgroudCheckBanner,
  Mission,
  OnlinePortal,
  ServicesBanner,
  AirCanada,
  Virginatlantic,
  DHL,
  Menzies,
  Easyjet,
  BritishAirway,
  Candidate,
  ExpertiseSeamless,
  PictureOne,
  PictureTwo,
  PictureThree,
  PictureFour,
  PictureFive,
  PictureSix,
  Wilson,
  OCS,
} from "../components/UIComponent/images";

export const BackgroundCheckData = [
  {
    imageContentData: [
      {
        pageName: "",
        bannerLogo: ``,
        title:
          "Propel your background checks<strong> without any turbulence</strong>",
        description:
          "We have a wealth of knowledge and experience to help dramatically reduce waiting times for airside passes so you can focus on onboarding and upskilling your team faster.",
        src: `${BackgroudCheckBanner}`,
        ctaText: "Learn More",
        ctaLink: "#",
        background: true,
        figureFirst: false,
        banner: true,
        contactState:"",
        scrollSection: "our-approach",
      },
      {
        pageName: "",
        bannerLogo: ``,
        title: "Expertise that's<strong> miles</strong> ahead of the curve",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non condimentum risus. Ut dignissim magna eu tincidunt tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non condimentum risus. Ut dignissim magna eu tincidunt tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        src: `${Mission}`,
        ctaText: "Sign Up Now",
        ctaLink: "/contact",
        background: false,
        figureFirst: true,
        banner: false,
        contactState:"background-checks",
      },
      {
        pageName: "",
        bannerLogo: ``,
        title: "With our bespoke systems, getting your workforce airside is a <strong>breeze</strong>",
        description:
          "Rest assured, you’re in safe hands! Our background check process is fully CAA compliant. We’re also ISO 27001 accredited, with security controls in place to safeguard your data from cyber threats.",
        src: `${ExpertiseSeamless}`,
        ctaText: "",
        ctaLink: "",
        background: false,
        figureFirst: false,
        banner: false,
        contactState:"background-checks",
      },
    ],
  },
  {
    ourApproach: {
      title: "Our Approach",
      description:
        "Our highly-skilled team and digitised vetting process make background checks a breeze. With over 20 years of combined experience and signatory status across most major UK airports, we clear high volumes of airside passes whilst achieving unrivalled turnaround times.",
    },
  },
  {
    onlinePortal: {
      title:
        "Keep track of our referencing progress in real-time through our online portal system.",
      description:
        "Simply log-in and view real-time referencing progress for all of your candidates. Our system can easily integrate with your existing processes, making the on-boarding experience for candidates totally seamless.",
      ctaText: "Sign Up Now",
      ctaLink: "#",
      imgSrc: `${OnlinePortal}`,
    },
  },
  {
    candidatesData: {
      title:
        "Nothing but <strong>clear skies</strong> for happy clients and candidates!",
      candidatesList: [
        {
          candidateImg: `${PictureOne}`,
          brandLogo: `${Virginatlantic}`,
          brandMobile: `${Virginatlantic}`,
          quotes:
            "“Shayla was amazing and so positive! She went above and beyond to make sure I got my pass in time for my first flight. She was an absolute dream to work with.”",
          candidateName: "Cabin Crew",
          candidateDesignation: "Virgin Atlantic",
        },
        {
          candidateImg: `${PictureTwo}`,
          brandLogo: `${DHL}`,
          brandMobile: `${DHL}`,
          quotes:
            "“The support and help I received from Holly was amazing. She does her job in a very professional manner and helped me with everything without delaying the process. Thank you, Holly, you're a star!”",
          candidateName: "Check-in Agent",
          candidateDesignation: "DHL",
        },
        {
          candidateImg: `${PictureThree}`,
          brandLogo: `${Virginatlantic}`,
          brandMobile: `${Virginatlantic}`,
          quotes:
            "“Elise was fantastic and efficient in getting my Airside Pass. She kept me informed of the progress at every stage. She is a great asset to Optime. Thanks again!”",
          candidateName: "Cabin Crew",
          candidateDesignation: "Virgin Atlantic",
        },
        {
          candidateImg: `${PictureFour}`,
          brandLogo: `${Wilson}`,
          brandMobile: `${Wilson}`,
          quotes:
            "“Fleur & Cassia were both so friendly and helpful. I found everyone from Optime easy to communicate with and would highly recommend them!”",
          candidateName: "Passenger Service Agent",
          candidateDesignation: "Wilson James",
        },
        {
          candidateImg: `${PictureFive}`,
          brandLogo: `${OCS}`,
          brandMobile: `${OCS}`,
          quotes:
            "“Megan was brilliant to work with. She was patient, attentive and full of good energy! I thoroughly recommend her and Optime.”",
          candidateName: "Warehouse Operative",
          candidateDesignation: "OCS",
        },
        {
          candidateImg: `${PictureSix}`,
          brandLogo: `${Menzies}`,
          brandMobile: `${Menzies}`,
          quotes:
            "“Bebiana was so helpful and responded to all of my questions on time. She was well-organised and provided great customer service. Huge thanks to everyone at Optime.”",
          candidateName: "Baggage Handler",
          candidateDesignation: "Menzies",
        },
      ],
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non condimentum risus. Ut dignissim magna eu tincidunt tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      ctaText: "Sign Up Now",
      ctaLink: "#",
    },
  },
  {
    brandsData: {
      title: "Brands we work with",
      brandsLogo: [
        `${AirCanada}`,
        `${Virginatlantic}`,
        `${DHL}`,
        `${Menzies}`,
        `${Easyjet}`,
        `${BritishAirway}`,
      ],
      brandsLogoMobile: [
        [`${AirCanada}`, `${Virginatlantic}`, `${DHL}`, `${Menzies}`],
        [`${Easyjet}`, `${BritishAirway}`],
      ],
    },
  },
];
