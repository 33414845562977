import React from "react";
import FormContainer from "./Form";

const ContactBanner = (props) => {
  const { imgSrc, title, description, } = props.banner;


  return (
    <div className="contact-banner">
      <div className="wrapper ">
        <div className="image-content-data d-flex justify-content-between align-items-center">
          <div className="content">
            <h1 className="title" dangerouslySetInnerHTML={{ __html: title }} />
          </div>
          <figure>
            <img src={imgSrc} />
          </figure>
        </div>
        <div className="contact-form-content">
          <p>{description}</p>
          <FormContainer select={true} formState={props.formState} />
        </div>
      </div>
    </div>
  );
};

export default ContactBanner;
