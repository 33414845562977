import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { getDatabase, ref, set, push, child } from "firebase/database";
import * as Yup from "yup";
import "yup-phone";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

const NewsletterForm = () => {

  const db = getDatabase();
  const newPostKey = push(child(ref(db), 'email')).key;
  const [formState, setFormState] = useState(false);
  

  return (
    <div>
      <Formik
        initialValues={{ email: "", checked: "" }}
        validationSchema={SignupSchema}
        // onSubmit={(values, { setSubmitting }) => {
        //   setTimeout(() => {
        //     alert(JSON.stringify(values, null, 2));
        //     setSubmitting(false);
        //   }, 400);
        // }}
        onSubmit={(values, { resetForm}) => {
          // same shape as initial values
          console.log(values);
          set( ref(db, 'email/' + newPostKey),  {
            Email: values.email,
            Checked: values.checked,
          })

          setFormState(true);

          setTimeout(() => {
            setFormState(false);
          }, 2000)

          resetForm({values : '' })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="newsletter-form-container">
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder="email address"
                />

                <span className="error-message">
                  {errors.email && touched.email && errors.email}
                </span>
                <button
                  type="submit"
                  className="submit-button"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
                {formState ? (
                <p className="message">All done, thank you!</p>
               ) : null}
              </div>

              <div className="form-group">
                <label className="d-flex align-items-center">
                  <Field type="checkbox" name="checked" />
                  <span className="checkmark"></span>I confirm I am happy to
                  recieve email alerts from Optime
                </label>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default NewsletterForm;
